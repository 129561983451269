import { POPUP_FOCUS, SAVE_AND_DOWNLOAD_PDF, SAVE_PDF } from "src/constants";
import { FontCollection } from "src/lib/services/font-collection";
import { Page } from "src/lib/page";
import { IEvent, IFont } from "src/types/event";
import {
  addStyles,
  containsElement,
  createTemplate,
  eventPath,
  getPositionPercentage,
  removeElements,
  respectivePosition,
} from "src/utils/dom";
import { Annotation } from "../../annotation";
import { AbstractTool } from "../abstract-tool";
import { clone } from "lodash";
import { filterXSS } from "xss";

export class Tool extends AbstractTool {
  public id;
  public className;

  private text = "";
  private font: IFont = null;
  private color = "";
  private fontSize = "";
  private positions = {
    screen: {
      x: 0,
      y: 0,
    },
    print: {
      x: 0,
      y: 0,
    },
  };
  private annotation: Annotation = null;

  private styleIds = {
    textarea: {
      font: "",
      color: "",
      fontSize: "",
    },
  };

  public page;
  private root;

  // handlers
  private clickHandler;
  private textChangeHandler;
  private fontChangeHandler;
  private colorChangeHandler;
  private fontSizeChangeHandler;
  private addHandler;
  private cancelHandler;

  // services
  private annotationService: Annotation;
  private fontCollectionService: FontCollection;

  constructor(private pageService: Page) {
    super();

    // TODO: Inject via dependency injection
    this.annotationService = new Annotation();
    this.fontCollectionService = new FontCollection();
    this.actionService = this.pageService.editor.actionService;

    this.id = `editor-page-annotation-popup-${this.pageService.getId()}`;
    this.className = "editor-page-annotation-popup";

    this.styleIds.textarea.font = `${this.id}-textarea-font`;
    this.styleIds.textarea.color = `${this.id}-textarea-color`;
    this.styleIds.textarea.fontSize = `${this.id}-textarea-fontSize`;

    this.root = this.pageService.getRoot();
    this.page = this.pageService.getPage();

    this.font = this.pageService.getEditor().defaultFont();
    this.color = this.pageService.getEditor().defaultFontColor();
    this.fontSize = this.pageService.getEditor().defaultFontSize();

    // Bindings
    this.bindHandlers();
  }

  public initialize() {
    this.page.addEventListener("click", this.clickHandler);

    const style = `.pf{ cursor: text; }`;
    addStyles(`${this.className}-annotation-general`, style, this.root);
  }

  // Events
  handleClick(event) {
    const path = eventPath(event);

    // Process popup events
    if (containsElement(path, this.id)) {
      return;
    }

    if (
      containsElement(path, this.pageService.deleteHandleClass, true) ||
      containsElement(path, this.pageService.resizeHandleClass, true) ||
      containsElement(path, this.pageService.dragHandleClass, true)
    ) {
      return;
    }

    this.showPopup(event);

    // Close other popup
    this.pageService.editor.broadcast({
      type: POPUP_FOCUS,
      data: { page: { id: this.pageService.getId() } },
    });
  }

  handleTextChange(event) {
    this.text = filterXSS(event.srcElement.value);
  }

  handleFontChange(event) {
    const id = event.target.value;
    const font = this.pageService.editor.getFont(id);
    if (!font) return;

    this.font = font;

    const head = this.pageService.getRoot();

    const style = `#${this.id} textarea { font-family: "${font.styleName}"; }`;
    addStyles(this.styleIds.textarea.font, style, head);
  }

  handleColorChange(event) {
    const color = (this.color = event.target.value);

    const head = this.pageService.getRoot();

    const style = `#${this.id} textarea { color: ${color}; }`;
    addStyles(this.styleIds.textarea.color, style, head);
  }

  handleFontSizeChange(event) {
    const fontSize = (this.fontSize = event.target.value);

    const head = this.pageService.getRoot();

    const style = `#${this.id} textarea { font-size: ${fontSize + "px"}; }`;
    addStyles(this.styleIds.textarea.fontSize, style, head);
  }

  handleAdd(event) {
    const { popup, textarea } = this.elements();
    if (!popup || !this.text) return;

    let { color, font, fontSize } = this;

    const oldAnnotation = clone(this.annotation);

    const annotation = this.annotation || this.annotationService.instance();

    const data = {
      text: this.text,
      positions: this.positions,
      font,
      color,
      fontSize,
      id: annotation.id,
      revision: this.pageService.editor.revision,
    };

    annotation.initialize(data);
    const editAnnotation = clone(annotation);

    this.pageService.createItem(annotation);

    this.createAction(oldAnnotation, editAnnotation);

    // Clear elements
    textarea.value = "";

    // Clear states
    this.text = "";
    this.annotation = null;

    // Close popup
    this.closePopup();
  }

  handleCancel(event) {
    const { popup, textarea } = this.elements();

    // Clear elements
    textarea.value = "";

    // Clear states
    this.text = "";
    this.annotation = null;

    this.closePopup();
  }

  handlePopupFocus(event) {
    const { data } = event;

    if (data.page.id !== this.page.id) {
      this.closePopup();
    }
  }

  handleSave(event) {
    this.closePopup();
  }

  showPopup(event) {
    this.create();

    const { popup, textarea, select, input } = this.elements();

    // Clear popup
    this.clear();

    // Get annotation value if clicked on it

    const path = eventPath(event);

    let annotation: Annotation = null;
    if (containsElement(path, Annotation.identifier, true)) {
      const element = path[1];
      this.annotation = annotation = this.pageService.getItem(
        element.id
      ) as Annotation;
      if (!annotation) return;

      // Popup disable while mode is SG
      if (annotation && annotation.checkEditable) return;

      this.text = textarea.value = this.text = annotation.text;
    }

    // Add popup positions
    this.positions = this.getPositions(event, annotation);

    const media = this.positionStyles(this.positions);
    addStyles(popup.id, media, this.root);

    // Add textarea styles
    const font = (this.font = annotation ? annotation.font : this.font);
    const color = (this.color = annotation ? annotation.color : this.color);
    const fontSize = (this.fontSize = annotation
      ? annotation.fontSize
      : this.fontSize);

    const fontStyle = `#${popup.id} textarea { font-family: "${
      font.styleName
    }"; color: ${color}; font-size:${fontSize + "px"}}`;
    addStyles(this.styleIds.textarea.font, fontStyle, this.root);

    const colorStyle = `#${popup.id} textarea { color: ${color}; }`;
    addStyles(this.styleIds.textarea.color, colorStyle, this.root);

    const fontSizeStyle = `#${popup.id} textarea { font-size:${
      fontSize + "px"
    } }`;
    addStyles(this.styleIds.textarea.fontSize, fontSizeStyle, this.root);

    input.value = color;
    // input.value = fontSize
    select.value = font.id;

    textarea.focus();
  }

  closePopup() {
    const popup = this.element();

    if (popup) {
      const media = { screen: { display: "none" } };
      addStyles(popup.id, media, this.root);
    }
  }

  create() {
    let { popup } = this.elements();

    if (popup) return popup;

    // Create
    const template = createTemplate(this.template());
    this.page.appendChild(template.content);

    // Bind events
    popup = this.element();
    this.toggleEventListners(popup);

    // Add popup styles
    addStyles(`${this.className}-general`, this.styles(), this.root);

    return popup;
  }

  getPositions(event, annotation) {
    let positions = respectivePosition(event, this.page);
    if (annotation) {
      positions = annotation.positions;
    }
    return positions;
  }

  clear() {
    const { textarea } = this.elements();
    textarea.value = this.text = "";
    this.annotation = null;
  }

  element() {
    return this.page.querySelector(`.${this.className}`);
  }

  elements() {
    const popup: HTMLDivElement = this.element();
    const textarea: HTMLTextAreaElement = popup
      ? popup.querySelector("textarea")
      : null;
    const select: HTMLSelectElement = popup
      ? popup.querySelector("select")
      : null;
    const input: HTMLInputElement = popup ? popup.querySelector("input") : null;
    return { popup, textarea, select, input };
  }

  template() {
    const fontOptions = this.fontCollectionService.getSelectableFonts();
    const options = [];
    for (const font of fontOptions) {
      options.push(
        `<option class="font-${font.id}" value="${font.id}">${font.name}</option>`
      );
    }

    return `<div id="${this.id}" class="${this.className}">
            <textarea></textarea>
            <div class="divider"></div>
            <div class="fonts-container">
                <div class="fonts">
                    <select>
                        ${options.join(" ")}
                    </select>
                </div>
                <div class="color">
                    <input type="color">
                </div>
                <div class="fontSize">
                    <select id="fontSize" type="fontSize">
                        <option value='10'>10</option>
                        <option value='12'>12</option>
                        <option value='14'>14</option>
                        <option value='16'>16</option>  
                        <option value='18'>18</option>
                        <option value='20' selected>20</option>
                        <option value='22'>22</option>
                        <option value='24'>24</option>
                        <option value='26'>26</option>
                        <option value='28'>28</option>
                        <option value='30'>30</option>
                    </select>
                </div>
                <div class="text-field-button text-field-button__apply" title="Add">
                   <button style="color:white;background-color:black;border: 1px solid black;
                   border-radius: 3px;">Place Text</button>
                </div>
                <div class="text-field-button text-field-button__cancel" title="Cancel">
                    <span class="material-icons cancel">highlight_off</span>
                </div>
            </div>
        </div>`;
  }

  styles() {
    let fontStyles = [];
    const fontOptions = this.fontCollectionService.getSelectableFonts();
    for (const font of fontOptions) {
      fontStyles.push(
        `.${this.className} .font-${font.id} { font-family: "${font.styleName}"; font-size: "${this.fontSize} + px";}`
      );
    }

    return `
            .${this.className} {
                background-color: white;    
                border: 2px solid gray;
                border-radius: 3px;
                z-index: 50;
                display: block;
                position: absolute;
            }

            .${this.className} textarea {
                outline: none;
                border: none;
                width: 100%;
            }

            ${fontStyles.join(" ")}

            .${this.className} .fonts-container {
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-bottom: 7px;
            }

            .${this.className} div {
                margin-left: 5px;
            }

            .${this.className} div:last-child {
                margin-right: 5px;
            }

            .${this.className} select {
                font-size: 12px;
            }

            .${this.className} input {
                height: 20px;
            }

            .${this.className} .text-field-button {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .${this.className} .divider {
                width: 100%;
                height: 1px;
                background-color: gray;
                margin-left: 0px;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            .${this.className} .add {
                color: #F25623;
            }

            .${this.className} .cancel {
                color: red;
            }
            
            @media print {
                .${this.className}, .hide-in-print {
                    display: none !important;
                }
            }
        `;
  }

  positionStyles(positions) {
    return {
      screen: Object.assign(
        {},
        {
          left: `${positions.screen.x}%`,
          top: `${positions.screen.y}%`,
        }
      ),
      print: Object.assign(
        {},
        {
          left: `${positions.print.x}%`,
          top: `${positions.print.y}%`,
        }
      ),
    };
  }

  bindHandlers() {
    this.clickHandler = this.handleClick.bind(this);
    this.textChangeHandler = this.handleTextChange.bind(this);
    this.fontChangeHandler = this.handleFontChange.bind(this);
    this.colorChangeHandler = this.handleColorChange.bind(this);
    this.fontSizeChangeHandler = this.handleFontSizeChange.bind(this);
    this.addHandler = this.handleAdd.bind(this);
    this.cancelHandler = this.handleCancel.bind(this);
  }

  toggleEventListners(popup: Element, remove = false) {
    const method = remove ? "removeEventListener" : "addEventListener";

    if (!popup) return;

    // Add textarea events
    const textarea = popup.querySelector("textarea");
    if (textarea) {
      textarea[method]("keyup", this.textChangeHandler);
    }

    // Add font events
    const select = popup.querySelector(".fonts-container select");
    if (select) {
      select[method]("change", this.fontChangeHandler);
    }

    // Add color events
    const colorPicker = popup.querySelector(
      ".fonts-container input[type=color]"
    );
    if (colorPicker) {
      colorPicker[method]("change", this.colorChangeHandler);
    }

    // Add Font Size events
    const fontSizePicker = popup.querySelector(
      ".fonts-container select[type=fontSize]"
    );
    if (fontSizePicker) {
      fontSizePicker[method]("change", this.fontSizeChangeHandler);
    }

    // Add button events
    const addButton = popup.querySelector(".text-field-button__apply");
    if (addButton) {
      addButton[method]("click", this.addHandler);
    }

    const cancelButton = popup.querySelector(".text-field-button__cancel");
    if (cancelButton) {
      cancelButton[method]("click", this.cancelHandler);
    }
  }

  public broadcast(event: IEvent) {
    const { type } = event;

    // Handle events
    if (type === POPUP_FOCUS) {
      this.handlePopupFocus(event);
    }

    if (type === SAVE_PDF || type === SAVE_AND_DOWNLOAD_PDF) {
      this.handleSave(event);
    }
  }

  public instance() {
    return new Tool(this.pageService);
  }

  public cleanup(dom) {
    const identifiers = [
      `.${this.className}`,
      `#${this.className}-annotation-general`,
      `#${this.className}-general`,
      `#${this.id}-screen`,
      `#${this.id}-print`,
      `#${this.styleIds.textarea.font}`,
      `#${this.styleIds.textarea.color}`,
      `#${this.styleIds.textarea.fontSize}`,
    ];

    for (const identifier of identifiers) {
      removeElements(identifier, dom);
    }
  }

  public destroy() {
    this.page.removeEventListener("click", this.clickHandler);

    const { popup } = this.elements();
    if (popup) {
      this.toggleEventListners(popup, true);
      popup.remove();
    }

    this.cleanup(this.root);
  }
}
