import { IActionType } from "src/types/event"
import { AbstractPageItem } from "../abstract-page-item"
import { Action } from "../action"

export abstract class AbstractTool {

    public actionService
    public page

    createAction(oldPageItem: AbstractPageItem, newPageItem: AbstractPageItem) {
        let change: any = { oldStates: {}, newStates: {} }
        let actionType = IActionType.ADD
        if (oldPageItem) {
            change = { oldStates: oldPageItem.jsonData(), newStates: newPageItem.jsonData() }
            actionType = IActionType.EDIT
        }

        const action = new Action()
        action.initialize(actionType, newPageItem, this.page.id, change)
        this.actionService.addAction(action)
    }
}