import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ApiService } from "./api.service";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { parseToken } from "./utils";
import { FileService } from "../services/file.service";
import { Editor } from "src/lib/editor";
import { IEvent, IPageItems, IToolChange } from "src/types/event";
import { downloadFile } from "src/utils/dom";
import { environment } from "src/environments/environment";
import { isEmpty, merge } from "lodash";
import { V1Compatibility } from "src/lib/legacy/v1-compatiblity";
import { SAVE_AND_DOWNLOAD_PDF } from "src/constants";

@Component({
  selector: "pdf-component",
  templateUrl: "./pdf.component.html",
  styleUrls: ["./pdf.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class PDFComponent implements OnInit {
  @Output() valueEmitter = new EventEmitter<string>();

  public html;
  public editor: Editor;

  public id = "page-container";

  public isLoading;
  public activityMessage;

  public token;
  public fileName;
  public pageItems: IPageItems;
  public components = [];

  public tool: IToolChange;
  public mode: any;

  public isEditorReady = false;
  public test;
  private v1CompatibilityService: V1Compatibility;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly api: ApiService,
    private readonly fileService: FileService
  ) {}

  ngOnInit() {
    localStorage.clear();
    this.route.queryParams.subscribe(
      (params: Params) => {
        const { token } = params;
        if (!token) return;

        this.token = token;
        this.isLoading = true;
        this.activityMessage = "Loading file, Please Wait...";

        const decodeToken = parseToken(params.token);
        this.api.url = decodeToken.server_url;
        // Load file
        this.fileService.api.url = decodeToken.server_url;
        this.fileService.loadData(token).then((response: any) => {
          this.mode = response.body.mode;
          localStorage.setItem("mode", this.mode);
        });
        this.fileService.loadFile(token).then((response: any) => {
          const { html, fileName, pageItems, components, version, revision } =
            response;

          this.html = html;
          this.fileName = fileName || "";
          this.pageItems = pageItems || {};

          // Load editor
          setTimeout(() => {
            if (!isEmpty(components) && !version) {
              this.v1CompatibilityService = new V1Compatibility(this.id);
              const items =
                this.v1CompatibilityService.getPageItems(components);
              this.pageItems = merge(this.pageItems, items);
            }

            Object.keys(this.pageItems).forEach((item) => {
              this.pageItems[item].forEach((data) => {
                data.signed = 0;
                if (this.mode == "SG") data.checkEditable = true;
              });
            });

            this.editor = new Editor(this.id, {
              pageItems: this.pageItems || {},
              revision,
              version,
            });
            this.isLoading = false;
            this.isEditorReady = true;

            this.components = components || [];
          }, 1500);
        });
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error("Something happend, try again later please");
      }
    );
  }

  reloadPage() {
    location.reload();
  }

  public handleToolChange(event: IToolChange) {
    this.tool = event;
    if (this.editor !== undefined) this.editor.broadcast(event as IEvent);
  }

  public handleUndo(event: IEvent) {
    this.editor.broadcast(event);
  }

  public handleSave(event: IEvent) {
    if (!this.token) return;

    this.isLoading = true;
    this.activityMessage = "Saving file, Please Wait...";

    const dom = this.editor.dom();
    this.editor.broadcast(Object.assign(event, { data: { dom } }));
    const html = this.editor.html(dom);

    const items = this.editor.getPageItems();
    const revision = this.editor.revision;
    const version = this.editor.getVersion();
    const signaturePayload = this.editor.getSignaturePayload();

    this.fileService
      .saveFile(
        this.token,
        html.outerHTML,
        items,
        revision,
        version,
        this.fileName,
        this.components,
        signaturePayload
      )
      .then((data) => {
        if (event.type === SAVE_AND_DOWNLOAD_PDF) {
          downloadFile(data, this.fileName);
        }

        this.toastr.success("File successfully saved");
        if (this.mode == "SG") this.mode = "close";
      })
      .catch((error) => {
        console.error(error);
        this.toastr.error("Happened some error, try again later please");
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
