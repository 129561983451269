import { Injectable } from "@angular/core";
import { isEmpty } from "lodash";
import { ApiService } from "src/app/api.service";

@Injectable()
export class FileService {
  constructor(public api: ApiService) {}

  public async loadData(token: string) {
    return await this.api.getData(token).toPromise();
  }

  public loadFile(token: string) {
    return new Promise((resolve, reject) => {
      this.api
        .getFile(token)
        .toPromise()
        .then((file: Blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            const type = String(reader.result).split(";")[0];
            const allowedTypes = [
              "data:application/json",
              "data:application/pdf",
            ];

            if (
              !["data:application/json", "data:application/pdf"].includes(type)
            ) {
              return reject(
                "Sended wrong file, please reload page and try again."
              );
            }

            if (type === allowedTypes[0]) {
              try {
                const {
                  html,
                  pageItems,
                  version,
                  revision,
                  components,
                  payload,
                } = JSON.parse(
                  JSON.parse(atob(String(reader.result).split(",")[1]))[
                    "user_annotation"
                  ]
                );

                if (!html) {
                  // console.log("html", html);
                }

                return resolve({
                  html,
                  pageItems,
                  version,
                  revision,
                  filename: "",
                  components,
                  payload,
                });
              } catch (error) {
                console.error("Unable to load file", error);
                return resolve({
                  html: undefined,
                  pageItems: [],
                  version: "",
                  revision: "",
                  filename: "",
                  components: [],
                  payload: {},
                });
              }
            }

            if (type === allowedTypes[1]) {
              this.api
                .getHtmlFromBase64Pdf(token, reader.result)
                .subscribe((data) =>
                  resolve({
                    html: data["content"],
                    pageItems: [],
                    version: "",
                    revision: 1,
                    components: [],
                    filename: data["filename"],
                  })
                );
            }
          };

          if (file) {
            reader.readAsDataURL(file);
          }
        });
    });
  }

  public saveFile(
    token,
    html,
    items,
    revision,
    version,
    fileName,
    components,
    signaturePayload
  ) {
    // console.log("hymlllllllllll", html);
    // Fix for the json parse issue.
    const processed = html.replace(new RegExp("&quot;", "g"), '\\"');
    // console.log("processssssssssed", processed);
    return new Promise((resolve, reject) => {
      this.api.sendHtml(processed, fileName, token).subscribe(
        (blob) => {
          const form = new FormData();
          form.append("document", blob, "annotate.pdf");
          form.append(
            "html",
            new Blob([processed], { type: "text/html" }),
            "content.html"
          );

          const data: any = { version, revision, pageItems: items, components };
          if (!isEmpty(signaturePayload)) {
            const { signature, initial } = signaturePayload;
            data.payload = { signature, initial, signed: 0 };
          }

          Object.keys(items).forEach((pageItem) => {
            items[pageItem].forEach((item) => {
              if (item.signed && item.signed == 1)
                data.payload = { ...data.payload, signed: 1 };
            });
          });

          // console.log(data);

          form.append("user_annotation", JSON.stringify(data));

          this.api.sendPdfToMainServer(token, form).subscribe((data) => {
            return resolve(blob);
          });
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }
}
