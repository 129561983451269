import { isEmpty, concat } from "lodash";
import { uniqueId } from "src/app/utils";
import { ANNOTATION } from "src/constants";
import { IFont } from "src/types/event";
import {
  createTemplate,
  getStyle,
  rgbColors,
  respectiveFontSize,
  rgbToHex,
  styleElements,
} from "src/utils/dom";
import { FontCollection } from "./services/font-collection";
import { AbstractPageItem } from "./abstract-page-item";
import { jsonData } from "src/utils/general";
import { filterXSS } from "xss";

export class Annotation extends AbstractPageItem {
  public id;
  public className = `editor-page-annotation-item`;

  public itemType = ANNOTATION;

  public text;
  public font: IFont;
  public color;
  public fontSize;
  public positions = { screen: { x: 0, y: 0 }, print: { x: 0, y: 0 } };
  public rectangle;

  public isMovable = true;
  public isRemovable = true;
  public isResizable = true;
  public isCopy = true;

  public checkEditable = false;

  public metaStyles = {};
  private fontCollectionService;

  public static identifier = "editor-page-annotation-item";

  constructor() {
    super();

    // TODO: Inject via dependency injection
    this.fontCollectionService = new FontCollection();
  }

  public initialize(data: any) {
    const {
      text,
      positions,
      font,
      color,
      fontSize,
      id,
      revision,
      rectangle,
      checkEditable,
      metaStyles,
    } = data;

    this.text = filterXSS(text);
    this.font = font;
    this.color = color;
    this.fontSize = fontSize;
    this.positions = positions;
    this.revision = revision;

    if (checkEditable) {
      this.checkEditable = checkEditable;
    }

    this.id = id ? id : uniqueId(this.className);
    this.className = `editor-page-annotation-item`;

    const element = this.element();
    if (element) {
      this.rectangle = rectangle || element.getBoundingClientRect();
    }

    this.metaStyles = metaStyles || {};

    return this;
  }

  public initializeFrom(element: Element) {
    if (!element) return null;

    this.text = element.childNodes[0].nodeValue; // Get value of immediate text node

    // styles
    const styles = getStyle(element, ["font-family", "color", "font-size"]);

    if (!isEmpty(styles)) {
      this.font = this.fontCollectionService.getFont(styles["font-family"]);
      this.color = rgbToHex(rgbColors(styles["color"]));
      this.fontSize = styles["font-size"];
    }

    // positions
    const position = (this.rectangle = element.getBoundingClientRect() as any);
    this.positions.screen = this.positions.print = {
      x: position.x,
      y: position.y,
    };

    this.id = element.id;
    this.className = element.className;

    return this;
  }

  public template() {
    const divTemplate = `<div class="${this.className}" id="${this.id}" draggable="true"><p>
       ${this.text}</p></div>`;

    const template = createTemplate(divTemplate);

    return template;
  }

  public styles(extraStyles = {}) {
    const fontSize = this.metaStyles["text-font-size"] || this.fontSize + "px";
    const width = this.metaStyles["div-width"]
      ? `${this.metaStyles["div-width"]}%`
      : "100px";
    const height = this.metaStyles["div-height"]
      ? `${this.metaStyles["div-height"]}%`
      : "auto";

    const position = this.positions.screen;

    const styles = {
      position: "absolute",
      left: `${position.x}%`,
      top: `${position.y}%`,
      width,
      height,
      "max-width": "20cm",
      padding: "5px",
    };

    const media = { screen: Object.assign(extraStyles, styles), print: styles };

    const textStyle = `#${
      this.id
    } p{ margin:0; font-size: ${fontSize}; color: ${
      this.color
    }; font-family: "${this.font ? this.font.styleName : "initial"}"; }`;

    return concat(
      styleElements(this.id, media),
      styleElements(`${this.id}-text`, textStyle)
    );

    // return styleElements(this.id, media)
  }

  public styleIds() {
    return [
      `#${this.id}`,
      `#${this.id}-screen`,
      `#${this.id}-print`,
      `#${this.id}-text`,
    ];
  }

  public handleResize(
    event,
    { newWidth, newHeight, percentWidth, percentHeight }
  ) {
    const parent = this.element();

    const text = parent.querySelector(".p");
    if (text) {
      const fontSize = respectiveFontSize(
        newWidth,
        newHeight,
        (this.text || "").length
      );
      this.metaStyles = { ...this.metaStyles, "text-font-size": fontSize };
    }

    this.metaStyles = {
      ...this.metaStyles,
      "div-width": percentWidth,
      "div-height": percentHeight,
    };

    this.hookAfter();
  }

  public hookAfter() {
    const element = this.element();

    const text = element.querySelector("p");

    if (!this.fontSize) this.fontSize = "20";
    text.setAttribute(
      "style",
      `font-family: ${this.font.styleName};font-size: ${this.fontSize}px; color: ${this.color};`
    );
  }

  public element(container: Element = null) {
    return (container || document).querySelector(`#${this.id}`);
  }

  public instance() {
    return new Annotation();
  }

  public jsonData() {
    return jsonData(this, ["fontCollectionService", "identifier"]);
  }
}
