import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, Component } from '@angular/core';

@Directive({
  selector: '[appDynamicContent]'
})
export class DynamicContentDirective implements OnInit {

  @Output() selectComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeComponent: EventEmitter<any> = new EventEmitter<any>();
  @Input() component: any;
  @Input() mode: any;
  @Input() content: any;

  @HostListener('click')
  onClick() {
    this.select();
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.component.params.value.startsWith("data:image/")) {
      const div = document.createElement('div');
      div.className = 'canvas-element';
      div.id = `chart-${this.component.id}`;
      div.style.position = 'absolute';
      div.style.whiteSpace = 'pre';
      div.style.zIndex = '10';
      div.style.cursor = 'pointer';
      div.style.transform = 'scale(1)';
      div.style.userSelect = 'none';
      div.style.display = 'flex';
      div.style.flexDirection = 'column';
      div.style.maxHeight = '300px';
      const img = document.createElement('img');
      img.className = 'img-element';
      if (!this.component.params.width && !this.component.params.height) {
        img.style.width = 'auto';
        img.style.height = '100%';
        img.style.maxHeight = '288px';
        img.style.maxWidth = '100%';
      } else {
        img.style.width = this.component.params.width + 'px';
        img.style.height = this.component.params.height + 'px';
      }
      img.src = this.component.params.value;
      const span = document.createElement('span');
      span.className = 'time-element';
      span.style.whiteSpace = 'normal'
      span.style.fontSize = '10px';
      span.style.fontFamily = 'arial';
      span.style.color = '#000000';
      span.style.bottom = '5px';
      span.innerText = this.component.params.timestamp;
      div.append(img);
      div.append(span);
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', div.outerHTML);
      const element = document.querySelector(`#chart-${this.component.id}`) as HTMLDivElement;
      const width1 = element.clientWidth;
      element.style.left = this.component.params.x + 'px';
      element.style.top = this.component.params.y + 'px';
      element.style.opacity = '1';
      const doc = document.querySelector('#pf1');
      if (doc) {
        const docWith1 = doc.clientWidth;
        const elemLeftPos1 = +element.style.left.split('px')[0];
        const elemWidth1 = width1 * 2;
        const getPosition = elemLeftPos1 + elemWidth1;
        if (getPosition > docWith1 - 20 && width1 < docWith1) {
          const position = docWith1 - elemWidth1 + ((elemWidth1 - width1) / 2) - 20;
          this.component.params.x = docWith1 - (elemWidth1 + 20);
          element.style.left = position + 'px';
        }
      }
    } else if (this.component.params.fontFamily && this.component.params.timestamp) {
      const div = document.createElement('div');
      div.className = 'div-element';
      div.id = `chart-${this.component.id}`;
      div.style.position = 'absolute';
      div.style.whiteSpace = 'pre';
      div.style.zIndex = '10';
      div.style.cursor = 'pointer';
      div.style.transform = 'scale(1)';
      div.style.userSelect = 'none';
      div.style.display = 'block';
      if (this.component.params.fontSize)
        div.style.fontSize = this.component.params.fontSize;
      const div1 = document.createElement('div');
      div1.className = 'text-element';
      div1.style.color = '#000000';
      div1.style.fontFamily = this.component.params.fontFamily;
      div1.style.letterSpacing = '.6px';
      div1.innerText = this.component.params.value;
      const span = document.createElement('span');
      span.className = 'time-element';
      span.style.whiteSpace = 'normal'
      span.style.fontSize = '10px';
      span.style.fontFamily = 'arial';
      span.style.color = '#000000';
      span.style.bottom = '5px';
      span.innerText = this.component.params.timestamp;
      div.append(div1);
      div.append(span);
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', div.outerHTML);
      const element = document.querySelector(`#chart-${this.component.id}`) as HTMLDivElement;
      const width1 = element.clientWidth;
      element.style.left = this.component.params.x + 'px';
      element.style.top = this.component.params.y + 'px';
      element.style.opacity = '1';
      const doc = document.querySelector('#pf1');
      if (doc) {
        const docWith1 = doc.clientWidth;
        const elemLeftPos1 = +element.style.left.split('px')[0];
        const elemWidth1 = width1 * 2;
        const getPosition = elemLeftPos1 + elemWidth1;
        if (getPosition > docWith1 - 20 && width1 < docWith1) {
          const position = docWith1 - elemWidth1 + ((elemWidth1 - width1) / 2) - 20;
          this.component.params.x = docWith1 - (elemWidth1 + 20);
          element.style.left = position + 'px';
        }
      }
    }
    else {
      const div = document.createElement('div');
      div.className = 'text-element';
      div.style.position = 'absolute';
      div.style.fontSize = '26px';
      div.style.whiteSpace = 'pre';
      div.style.zIndex = '10';
      if (this.mode == 'SG') div.style.color = '#000000';
      else div.style.color = '#00B2D9';
      div.style.cursor = 'pointer';
      div.style.userSelect = 'none';
      div.style.fontFamily = this.component.params.fontFamily;
      div.style.opacity = '0';
      div.style.letterSpacing = '.6px';
      div.id = `chart-${this.component.id}`;
      div.innerText = this.component.params.value;
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', div.outerHTML);
      const elem = document.querySelector(`#chart-${this.component.id}`) as HTMLDivElement;
      const width = elem.clientWidth;
      const height = elem.clientHeight;
      elem.style.left = this.component.params.x + 'px';
      elem.style.top = this.component.params.y + 'px';
      elem.style.opacity = '1';
      const doc = document.querySelector('#pf1');
      if (doc) {
        const docWith = doc.clientWidth;
        const elemLeftPos = +elem.style.left.split('px')[0];
        const elemWidth = width * 1.78;
        const getPosition = elemLeftPos + elemWidth;
        if (getPosition > docWith - 20 && width < docWith) {
          const position = docWith - elemWidth + ((elemWidth - width) / 2) - 20;
          this.component.params.x = docWith - (elemWidth + 20);
          elem.style.left = position + 'px';
        }
        if (elemWidth >= docWith - 20) {
          this.removeComponent.emit(this.component);
        }
      }
    }
  }

  public select() {
    this.selectComponent.emit(this.component);
  }

}
