import { Page } from "src/lib/page";
import { IEvent, ISignaturePayload } from "src/types/event";
import {
  addStyles,
  containsElement,
  eventPath,
  removeElements,
  respectivePosition,
} from "src/utils/dom";
import { Signature } from "../../signature";
import { AbstractTool } from "../abstract-tool";

export class Tool extends AbstractTool {
  public id;
  public className;
  public page;
  private root;
  private pages;
  private signature: ISignaturePayload;
  // handlers
  private clickHandler;
  private eventHandler;
  private cloneID;

  constructor(private pageService: Page, signature: ISignaturePayload, pages) {
    super();
    this.cloneID = signature.cloneID;
    this.signature = signature;

    this.actionService = this.pageService.editor.actionService;
    this.pages = pages;
    this.id = `editor-page-signature-${this.pageService.getId()}`;
    this.className = "editor-page-signature";
    this.root = this.pageService.getRoot();
    this.page = this.pageService.getPage();
    //signatures will only add on next pages
    if (
      signature.clone &&
      this.page.id.slice(2, 999) <= signature.currentPage.slice(2, 999)
    ) {
      // console.log(
      //   "current",
      //   signature.currentPage.slice(2, 999),
      //   "page",
      //   this.page.id.slice(2, 999)
      // );
      return;
    }

    // Bindings
    this.bindHandlers();
  }

  public initialize() {
    this.page.addEventListener("click", this.clickHandler);
    addStyles(`${this.className}-general`, this.styles(), this.root);

    return this;
  }

  // Events
  handleEvent(event) {
    const path = eventPath(event);
    if (containsElement(path, `${this.className}-item`, true)) {
      return;
    }
    this.handleMultipleAdd(event);
  }
  handleClick(event) {
    const path = eventPath(event);
    if (containsElement(path, `${this.className}-item`, true)) {
      return;
    }
    this.handleAdd(event);
  }

  handleAdd(event) {
    const signature = new Signature();
    signature.signed = 1;
    const position = respectivePosition(event, this.page);
    const data = Object.assign(
      {
        positions: position,
        revision: this.pageService.editor.revision,
      },
      this.signature
    );

    signature.initialize(data);

    this.pageService.createItem(signature);

    this.createAction(null, signature);
  }

  handleMultipleAdd(event) {
    const signature = new Signature();
    signature.signed = 1;
    const data = Object.assign(
      {
        positions: JSON.parse(localStorage.getItem("initial-placement")),
        revision: this.pageService.editor.revision,
      },
      this.signature
    );
    // console.log("2222222", this.signature.position);

    signature.initialize(data);
    signature.clone = true;

    signature.cloneID = this.cloneID;
    // console.log(this.signature.cloneID, "============", signature);
    this.pageService.createItem(signature);

    this.createAction(null, signature);
  }

  element() {
    return this.page.querySelector(`.${this.className}`);
  }

  styles() {
    return `
            .pf {
                cursor: crosshair;
            }
        `;
  }

  positionStyles(positions) {
    return {
      screen: Object.assign(
        {},
        {
          left: `${positions.screen.x}%`,
          top: `${positions.screen.y}%`,
        }
      ),
      print: Object.assign(
        {},
        {
          left: `${positions.print.x}%`,
          top: `${positions.print.y}%`,
        }
      ),
    };
  }

  bindHandlers() {
    if (this.signature.clone) {
      this.handleEvent(this);
    } else {
      this.clickHandler = this.handleClick.bind(this);
    }
  }

  public broadcast(event: IEvent) {}

  public cleanup(dom) {
    const identifiers = [
      `#${this.className}`,
      `#${this.className}-general`,
      `#${this.id}-screen`,
      `#${this.id}-print`,
    ];

    for (const identifier of identifiers) {
      removeElements(identifier, dom);
    }
  }

  public destroy() {
    this.page.removeEventListener("click", this.clickHandler);
    this.cleanup(this.root);
  }
}
