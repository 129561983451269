// Events
export const TOOL_CHANGE = 'TOOL_CHANGE'
export const SAVE_PDF = 'SAVE_PDF'
export const SAVE_AND_DOWNLOAD_PDF = 'SAVE_AND_DOWNLOAD_PDF'
export const POPUP_FOCUS = 'POPUP_FOCUS'
export const PAGE_FOCUS = 'PAGE_FOCUS'
export const SAVE_SIGNATURE = 'SAVE_SIGNATURE'
export const UNDO = 'UNDO'

// Classes
export const ANNOTATION = 'annotation'
export const SIGNATURE = 'signature'
export const HIGHLIGHT = 'highlight'

// Formats
export const A4 = 'A4'
export const A5 = 'A5'