import { isEmpty, isString } from "lodash";

export function percentageFactory(totalPixels, correction = 0) {
  return function (pixels) {
    const value = percentage(pixels + correction, totalPixels);
    return value > 100 ? 100 : value;
  };
}

export function percentage(pixels, totalPixels) {
  return (pixels * 100) / totalPixels;
}

export function respectivePercentage(pixels, parentPixels) {
  return (pixels * 100) / parentPixels;
}

export function createStyleElement(id, css) {
  const element = document.createElement("style");
  const textNode = document.createTextNode(css);
  element.id = id;
  element.appendChild(textNode);
  return element;
}

export function appendElement(container, element, overwrite = false) {
  const existingElements = element.id
    ? container.querySelectorAll("#" + element.id)
    : null;

  if (overwrite && !isEmpty(existingElements)) {
    existingElements.forEach((existingElement) => {
      existingElement.remove();
    });
  }

  container.appendChild(element);
}

export function removeElements(id, container) {
  const elements = (container || document).querySelectorAll(id);
  if (!isEmpty(elements)) {
    elements.forEach((element) => element.remove());
  }
}

export function createCssText(id, style, wrapper = "") {
  let cssText = "";

  for (const property in style) {
    if (!style.hasOwnProperty(property)) continue;
    cssText += property + ": " + style[property] + ";";
  }

  cssText = "#" + id + "{" + cssText + "}";

  if (wrapper) {
    cssText = wrapper + "{" + cssText + "}";
  }

  return cssText;
}

export function respectivePosition(event: any, container) {
  const x = event.clientX;
  const y = event.clientY;

  return getPositionPercentage(x, y, container);
}

export function getPositionPercentage(x, y, container, absolute = true) {
  const containerDimensions = container.getBoundingClientRect();

  const itemWidthPercentage = percentageFactory(containerDimensions.width);
  const itemHeightPercentage = percentageFactory(containerDimensions.height);
  const itemPrintWidthPercentage = percentageFactory(containerDimensions.width);
  const itemPrintHeightPercentage = percentageFactory(
    containerDimensions.height
  );

  let left = x - containerDimensions.x;
  let top = y - containerDimensions.y;

  if (!absolute) {
    left = x;
    top = y;
  }

  return {
    screen: { x: itemWidthPercentage(left), y: itemHeightPercentage(top) },
    print: {
      x: itemPrintWidthPercentage(left),
      y: itemPrintHeightPercentage(top),
    },
  };
}

export function dragEnd(
  event,
  container,
  head,
  scanStyles = { screen: [], print: [] },
  writeMetadata = false,
  applyStyles = true
) {
  const positions = respectivePosition(event, container);

  return positions;
}

export function addInlineStyle(element, styles) {
  let inlineStyle = "";
  for (const [key, value] of Object.entries(styles)) {
    inlineStyle += `${key}:${value};`;
  }
  element.setAttribute("style", inlineStyle);
}

export function styleElements(id, media) {
  const styleElements = [];

  if (isString(media)) {
    styleElements.push(createStyleElement(id, media));
    return styleElements;
  }

  for (const [key, styles] of Object.entries(media)) {
    const wrapper = key === "print" ? "@media print" : "@media screen";

    const css = createCssText(id, styles, wrapper);
    const styleElement = createStyleElement(id + `-${key}`, css);

    styleElements.push(styleElement);
  }

  return styleElements;
}

export function addStyles(id, media, head, overwrite = true) {
  const elements = styleElements(id, media);

  for (const element of elements) {
    appendElement(head, element, overwrite);
  }
}

export function createTemplate(html: string) {
  const template = document.createElement("template");
  template.innerHTML = html;
  return template;
}

export function containsElement(elements: Element[], id, className = false) {
  let contains = false;

  for (const element of elements) {
    const match = className
      ? element.classList
        ? element.classList.contains(id)
        : false
      : element.id === id;
    if (!match) continue;
    contains = true;
    break;
  }

  return contains;
}

export function elementRegistry() {
  const registry = {};
  const defaultContainer: Document = document;

  return function (identifier, container: Element, lazy = true): Element {
    // Load lazy
    if (lazy && registry[identifier]) {
      return registry[identifier];
    }

    // Get from dom
    const root: Element | Document = container || defaultContainer;
    const element: Element = root.querySelector(identifier);
    if (element) {
      registry[identifier] = element;
    }
    return element;
  };
}

export function getStyle(element, properties) {
  const pluckedStyle: any = {};

  const styles = getComputedStyle(element);
  for (const property of properties) {
    pluckedStyle[property] = styles[property];
  }

  return pluckedStyle;
}

export function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbColors(rgb) {
  let colors = rgb.split("(")[1].split(")")[0].split(",");
  colors = colors.map((color) => color.trim() * 1);
  return colors;
}

export function rgbToHex(rgb) {
  return (
    "#" +
    componentToHex(rgb[0]) +
    componentToHex(rgb[1]) +
    componentToHex(rgb[2])
  );
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function downloadFile(blob, fileName) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export function respectiveFontSize(
  parentWidth: number,
  parentHeight: number,
  textLength: number
) {
  const pixels = textLength > 0 ? parentWidth / textLength : 0;
  return pixels / 16;
  // const widthInRem = parentWidth / 16
  // const heightInRem = parentWidth / 16
  // return Math.min(widthInRem, heightInRem)
}

export function widthHeightPercentageChange(event, page, x, y) {
  if (event.x > 0) {
    const xChange = x - event.x;

    const yChange = y - event.y;

    const target = event.target as Element;
    const parent = target.parentNode as Element;

    const parentRect = parent.getBoundingClientRect();
    const pageRect = page.getBoundingClientRect();

    let newWidth = parentRect.width - xChange;
    let newHeight = parentRect.height - yChange;

    const width = respectivePercentage(newWidth, pageRect.width);
    const height = respectivePercentage(newHeight, pageRect.height);
    if (newHeight > 0 || newWidth > 0) {
      return {
        oldWidth: width,
        oldHeight: height,
        newWidth,
        newHeight,
        percentWidth: width,
        percentHeight: height,
      };
    } else {
      return {
        oldWidth: 0,
        oldHeight: 0,
        newWidth: 0,
        newHeight: 0,
        percentWidth: 0,
        percentHeight: 0,
      };
    }
  }
}
export function touchwidthHeightPercentageChange(event, page, x, y, target) {
  const clientX = event.touches ? event.touches[0].clientX : event.clientX;
  const clientY = event.touches ? event.touches[0].clientY : event.clientY;

  if (clientX > 0) {
    const xChange = x - clientX;
    const yChange = y - clientY;

    const parent = target.parentNode as Element;

    const parentRect = parent.getBoundingClientRect();
    const pageRect = page.getBoundingClientRect();

    let newWidth = parentRect.width - xChange;
    let newHeight = parentRect.height - yChange;

    const width = respectivePercentage(newWidth, pageRect.width);
    const height = respectivePercentage(newHeight, pageRect.height);

    if (newHeight > 0 || newWidth > 0) {
      return {
        oldWidth: width,
        oldHeight: height,
        newWidth,
        newHeight,
        percentWidth: width,
        percentHeight: height,
      };
    } else {
      return {
        oldWidth: 0,
        oldHeight: 0,
        newWidth: 0,
        newHeight: 0,
        percentWidth: 0,
        percentHeight: 0,
      };
    }
  }
}

export function touchrespectivePosition(event: any, container) {
  const x = event.changedTouches[0].clientX;
  const y = event.changedTouches[0].clientY;

  return getPositionPercentage(x, y, container);
}
export function eventPath(event) {
  return event.path || (event.composedPath && event.composedPath()) || [];
}
