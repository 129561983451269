// import { concat, isEmpty } from "lodash";
// import { uniqueId } from "src/app/utils";
// import { SIGNATURE } from "src/constants";
// import { IFont } from "src/types/event";
// import {
//   createTemplate,
//   getStyle,
//   respectiveFontSize,
//   styleElements,
//   widthHeightPercentageChange,
// } from "src/utils/dom";
// import { FontCollection } from "./services/font-collection";
// import { AbstractPageItem } from "./abstract-page-item";
// import { jsonData } from "src/utils/general";

// export class Signature extends AbstractPageItem {
//   public id;
//   public className = `editor-page-signature-item`;

//   public itemType = SIGNATURE;

//   // data
//   public type;
//   public data;
//   public dataType;
//   public timestamp;

//   public text: any;

//   public checkEditable = true;

//   // styles
//   public font: IFont;
//   public positions = { screen: { x: 0, y: 0 }, print: { x: 0, y: 0 } };

//   // dimensions
//   public rectangle;

//   //
//   public isMovable = true;
//   public isRemovable = true;
//   public isResizable = true;
//   public isCopy = true;
//   public clone = false;
//   public cloneID = "";

//   public resizableElements = [
//     { id: ".image", type: "image" },
//     { id: ".text", type: "text" },
//   ];

//   public metaStyles = {};

//   private fontCollectionService;

//   public static identifier = `editor-page-signature-item`;

//   constructor() {
//     super();

//     // TODO: Inject via dependency injection
//     this.fontCollectionService = new FontCollection();
//   }

//   public initialize(data: any) {
//     const {
//       type,
//       data: value,
//       dataType,
//       font,
//       timestamp,
//       positions,
//       id,
//       revision,
//       rectangle,
//       metaStyles,
//     } = data;

//     this.revision = revision;
//     this.type = type;
//     this.data = value;
//     this.dataType = dataType;
//     this.font = font;
//     this.timestamp = timestamp;

//     this.id = id ? id : uniqueId(this.className);

//     if (!isEmpty(positions)) {
//       this.positions = positions;
//     }

//     const element = this.element();
//     if (element) {
//       this.rectangle = rectangle || element.getBoundingClientRect();
//     }
//     this.metaStyles = metaStyles || {};

//     return this;
//   }

//   public initializeFrom(element: Element) {
//     if (!element) return null;

//     this.text = element.childNodes[0].nodeValue; // Get value of immediate text node

//     // styles
//     const styles = getStyle(element, ["font-family"]);

//     if (!isEmpty(styles)) {
//       this.font = this.fontCollectionService.getFont(styles["font-family"]);
//     }

//     // positions
//     const position = (this.rectangle = element.getBoundingClientRect() as any);
//     this.positions.screen = this.positions.print = {
//       x: position.x,
//       y: position.y,
//     };

//     this.id = element.id;
//     this.className = element.className;

//     return this;
//   }

//   public template() {
//     const signature =
//       this.dataType === "image"
//         ? `<div class="image"><img src="${this.data}" /></div>`
//         : `<svg viewBox="0 0 100 50" ><text y="14">${this.data}</text></svg>`;

//     let divTemplate;
//     if (this.type === "signature") {
//       divTemplate = `<div class="${this.className}" id="${this.id}" draggable="true">
//             ${signature}<div class="timestamp">${this.timestamp}</div></div>`;
//     } else {
//       divTemplate = `<div class="${this.className}" id="${this.id}" draggable="true" >${signature}</div>`;
//     }

//     const template = createTemplate(divTemplate);
//     return template;
//   }

//   public styles(extraStyles = {}) {
//     let width;
//     let height;
//     if (this.type === "signature") {
//       width = localStorage.getItem("signature-width")
//         ? `${localStorage.getItem("signature-width")}%`
//         : "auto";
//       height = localStorage.getItem("signature-height")
//         ? `${localStorage.getItem("signature-height")}%`
//         : "auto";
//     } else {
//       width = localStorage.getItem("initial-width")
//         ? `${localStorage.getItem("initial-width")}%`
//         : "10%";
//       height = localStorage.getItem("initial-height")
//         ? `${localStorage.getItem("initial-height")}%`
//         : "auto";
//     }

//     const fontSize = this.metaStyles["text-font-size"] || 1.5;
//     const position = this.positions.screen;

//     const styles = {
//       position: "absolute",
//       left: `${position.x}%`,
//       top: `${position.y}%`,
//       width,
//       display: "block",
//       "max-height": "fit-content",
//       " height": "fit-content !important",
//       "max-width": "30%",
//       "min-width": "50px",
//     };

//     const media = { screen: Object.assign(extraStyles, styles), print: styles };

//     // Inner styles
//     const textStyle = `#${
//       this.id
//     } .text{ font-size: ${fontSize}rem; font-family: "${
//       this.font ? this.font.styleName : "initial"
//     }"; }`;

//     const imageStyle = `#${this.id} .image img{ width: 100%;min-width:80px;}`;

//     const timestampStyle = `#${
//       this.id
//     } .timestamp{ font-size: 8pt; font-family: 'Arial'; padding-right: 8px;display:flex;justify-content:center;text-justify:center;text-align:center;width:${localStorage.getItem(
//       "signature-width"
//     )};}' } .timestamp p{
//       font-size:200px}
//     }`;

//     return concat(
//       styleElements(this.id, media),
//       styleElements(`${this.id}-text`, textStyle),
//       styleElements(`${this.id}-image`, imageStyle),
//       styleElements(`${this.id}-timestamp`, timestampStyle)
//     );
//   }

//   public styleIds() {
//     return [
//       `#${this.id}`,
//       `#${this.id}-screen`,
//       `#${this.id}-print`,
//       `#${this.id}-image`,
//       `#${this.id}-text`,
//       `#${this.id}-timestamp`,
//     ];
//   }

//   public handleResize(
//     event,
//     { newWidth, newHeight, percentWidth, percentHeight }
//   ) {
//     const parent = this.element();

//     const text = parent.querySelector(".text");
//     if (text) {
//       const fontSize = respectiveFontSize(
//         newWidth,
//         newHeight,
//         (this.data || "").length
//       );
//       this.metaStyles = { ...this.metaStyles, "text-font-size": fontSize };
//     }

//     const image = parent.querySelector(".image");
//     if (image) {
//       this.metaStyles = {
//         ...this.metaStyles,
//         "image-width": 100,
//         "image-height": 100,
//       };
//     }

//     this.metaStyles = {
//       ...this.metaStyles,
//       "div-width": percentWidth,
//       "div-height": percentHeight,
//     };

//     if (this.type === "signature") {
//       localStorage.setItem("signature-height", percentHeight);
//       localStorage.setItem("signature-width", percentWidth);
//     } else {
//       localStorage.setItem("initial-height", percentHeight);
//       localStorage.setItem("initial-width", percentWidth);
//     }

//     this.hookAfter();
//   }

//   public element(container: Element = null) {
//     return (container || document).querySelector(`#${this.id}`);
//   }

//   public hookAfter() {
//     const element = this.element();
//     if (this.dataType === "image" || !element) return;

//     const svg = element.querySelector("svg");
//     const text = element.querySelector("text");
//     text.setAttribute("style", `font-family: ${this.font.styleName};`);

//     const { x, y, width, height } = text.getBBox();

//     const viewBox = this.metaStyles["view-box"] || [x, y, width, height];
//     svg.setAttribute("viewBox", viewBox.join(" "));

//     this.metaStyles = { ...this.metaStyles, "view-box": viewBox };
//   }

//   public instance() {
//     return new Signature();
//   }

//   public jsonData() {
//     return jsonData(this, ["fontCollectionService", "identifier"]);
//   }
// }

import { concat, isEmpty } from "lodash";
import { uniqueId } from "src/app/utils";
import { SIGNATURE } from "src/constants";
import { IFont } from "src/types/event";
import {
  createTemplate,
  getStyle,
  respectiveFontSize,
  styleElements,
  widthHeightPercentageChange,
} from "src/utils/dom";
import { FontCollection } from "./services/font-collection";
import { AbstractPageItem } from "./abstract-page-item";
import { jsonData } from "src/utils/general";

export class Signature extends AbstractPageItem {
  public id;
  public className = `editor-page-signature-item`;

  public itemType = SIGNATURE;

  // data
  public type;
  public data;
  public dataType;
  public timestamp;

  public text: any;

  public checkEditable = true;

  // styles
  public font: IFont;
  public positions = { screen: { x: 0, y: 0 }, print: { x: 0, y: 0 } };

  // dimensions
  public rectangle;

  //
  public isMovable = true;
  public isRemovable = true;
  public isResizable = true;
  public isCopy = true;
  public clone = false;
  public cloneID = "";

  public resizableElements = [
    { id: ".image", type: "image" },
    { id: ".text", type: "text" },
  ];

  public metaStyles = {};

  private fontCollectionService;

  public static identifier = `editor-page-signature-item`;

  constructor() {
    super();

    // TODO: Inject via dependency injection
    this.fontCollectionService = new FontCollection();
  }

  public initialize(data: any) {
    const {
      type,
      data: value,
      dataType,
      font,
      timestamp,
      positions,
      id,
      revision,
      rectangle,
      metaStyles,
    } = data;

    this.revision = revision;
    this.type = type;
    this.data = value;
    this.dataType = dataType;
    this.font = font;
    this.timestamp = timestamp;

    this.id = id ? id : uniqueId(this.className);

    if (!isEmpty(positions)) {
      this.positions = positions;
    }

    const element = this.element();
    if (element) {
      this.rectangle = rectangle || element.getBoundingClientRect();
    }

    this.metaStyles = metaStyles || {};

    return this;
  }

  public initializeFrom(element: Element) {
    if (!element) return null;

    this.text = element.childNodes[0].nodeValue; // Get value of immediate text node

    // styles
    const styles = getStyle(element, ["font-family"]);

    if (!isEmpty(styles)) {
      this.font = this.fontCollectionService.getFont(styles["font-family"]);
    }

    // positions
    const position = (this.rectangle = element.getBoundingClientRect() as any);
    this.positions.screen = this.positions.print = {
      x: position.x,
      y: position.y,
    };

    this.id = element.id;
    this.className = element.className;

    return this;
  }

  public template() {
    const signature =
      this.dataType === "image"
        ? `<div class="image"><img src="${this.data}" /></div>`
        : `<svg viewBox="0 0 100 50"><text y="14">${this.data}</text></svg>`;

    let divTemplate;
    if (this.type === "signature") {
      divTemplate = `<div class="${this.className}" id="${this.id}" draggable="true">
            ${signature}<div class="timestamp">${this.timestamp}</div></div>`;
    } else {
      divTemplate = `<div class="${this.className}" id="${this.id}" draggable="true">${signature}</div>`;
    }

    const template = createTemplate(divTemplate);
    return template;
  }

  public styles(extraStyles = {}) {
    let width;
    let height;
    if (this.type === "signature") {
      width = localStorage.getItem("signature-width")
        ? `${localStorage.getItem("signature-width")}%`
        : "auto";
      height = localStorage.getItem("signature-height")
        ? `${localStorage.getItem("signature-height")}%`
        : "auto";
    } else {
      width = localStorage.getItem("initial-width")
        ? `${localStorage.getItem("initial-width")}%`
        : "10%";
      height = localStorage.getItem("initial-height")
        ? `${localStorage.getItem("initial-height")}%`
        : "auto";
    }

    const fontSize = this.metaStyles["text-font-size"] || 1.5;
    const position = this.positions.screen;

    const styles = {
      position: "absolute",
      left: `${position.x}%`,
      top: `${position.y}%`,
      width,
      height: "fit-content !important",
      "max-width": "30%",
      "min-width": "50px",
    };

    const media = { screen: Object.assign(extraStyles, styles), print: styles };

    // Inner styles
    const textStyle = `#${
      this.id
    } .text{ font-size: ${fontSize}rem; font-family: "${
      this.font ? this.font.styleName : "initial"
    }"; }`;

    const imageStyle = `#${this.id} .image img{ width: 100%;min-width:80px;}`;

    const timestampStyle = `
  #${this.id} .timestamp {
    margin: auto;
    font-size: 0.7rem;
    font-family: 'Arial';
    padding-right: 8px;
  }

  #${this.id} .timestamp p {
    margin: auto;
  }
`;

    return concat(
      styleElements(this.id, media),
      styleElements(`${this.id}-text`, textStyle),
      styleElements(`${this.id}-image`, imageStyle),
      styleElements(`${this.id}-timestamp`, timestampStyle)
    );
  }

  public styleIds() {
    return [
      `#${this.id}`,
      `#${this.id}-screen`,
      `#${this.id}-print`,
      `#${this.id}-image`,
      `#${this.id}-text`,
      `#${this.id}-timestamp`,
    ];
  }

  public handleResize(
    event,
    { newWidth, newHeight, percentWidth, percentHeight }
  ) {
    const parent = this.element();

    const text = parent.querySelector(".text");
    if (text) {
      const fontSize = respectiveFontSize(
        newWidth,
        newHeight,
        (this.data || "").length
      );
      this.metaStyles = { ...this.metaStyles, "text-font-size": fontSize };
    }

    const image = parent.querySelector(".image");
    if (image) {
      this.metaStyles = {
        ...this.metaStyles,
        "image-width": 100,
        "image-height": 100,
      };
    }

    this.metaStyles = {
      ...this.metaStyles,
      "div-width": percentWidth,
      "div-height": percentHeight,
    };

    if (this.type === "signature") {
      localStorage.setItem("signature-height", percentHeight);
      localStorage.setItem("signature-width", percentWidth);
    } else {
      localStorage.setItem("initial-height", percentHeight);
      localStorage.setItem("initial-width", percentWidth);
    }

    this.hookAfter();
  }

  public element(container: Element = null) {
    return (container || document).querySelector(`#${this.id}`);
  }

  public hookAfter() {
    const element = this.element();
    if (this.dataType === "image" || !element) return;

    const svg = element.querySelector("svg");
    const text = element.querySelector("text");
    text.setAttribute("style", `font-family: ${this.font.styleName};`);

    const { x, y, width, height } = text.getBBox();

    const viewBox = this.metaStyles["view-box"] || [x, y, width, height];
    svg.setAttribute("viewBox", viewBox.join(" "));

    this.metaStyles = { ...this.metaStyles, "view-box": viewBox };
  }

  public instance() {
    return new Signature();
  }

  public jsonData() {
    return jsonData(this, ["fontCollectionService", "identifier"]);
  }
}
