export const page = (event, identifier) => {
  const { target } = event;
  return target.closest(identifier);
};

export const parseToken = (token) => {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  return null;
};

export const uniqueId = (text = "") => {
  return `${text ? text + "-" : ""}${parseInt(Math.random() * 1000000 + "")}`;
};

export default { parseToken, page };
