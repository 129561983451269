import { isEmpty } from "lodash";
import { uniqueId } from "src/app/utils";
import { ANNOTATION, SIGNATURE } from "src/constants";
import { IPageItems } from "src/types/event";
import { getPositionPercentage } from "src/utils/dom";
import { getAllPages, isImage } from "src/utils/general";
import { Annotation } from "../annotation";
import { FontCollection } from "../services/font-collection";
import { Signature } from "../signature";

export class V1Compatibility {
  private pages: Element[] = [];
  private pageHeight;
  private fontCollectionService: FontCollection;

  constructor(id: string) {
    this.fontCollectionService = new FontCollection();

    const container: HTMLElement = document.getElementById(id);
    if (!container) return;

    this.pages = getAllPages(container) as Element[];

    if (isEmpty(this.pages)) return;

    this.pageHeight = this.pages[0].clientHeight;
  }

  isOldVersion(jsonPayload: any) {
    let isOldVersion = false;
    try {
      const payload = JSON.parse(jsonPayload);
      if (!isEmpty(payload.components)) {
        isOldVersion = true;
      }
    } catch (error) {
      console.error(error);
    }

    return isOldVersion;
  }

  getPageItems(components) {
    const pageItems: IPageItems = {};

    for (const component of components || []) {
      const type = this.type(component);
      let item = null;

      const pageNo = this.pageNo(component);
      const page = this.page(pageNo);

      // console.log(pageNo, page)

      if (!page) continue;

      // console.log(component)

      // console.log(page, type, component)

      // console.log(pageNo)
      if (type === ANNOTATION) {
        item = this.createAnnotation(component);
      }
      if (type === SIGNATURE) {
        item = this.createSignature(component);
      }
      if (item) {
        if (typeof pageItems[page.id] === "undefined") {
          pageItems[page.id] = [];
        }
        pageItems[page.id].push(item);
      }
    }

    return pageItems;
  }

  createAnnotation(component: any) {
    const annotation = new Annotation();
    const font = this.font(component.params.fontFamily || "");
    const color = "#000000";
    const fontSize = "20px";
    const id = `${annotation.className}-${component.id}`;
    const text = component.params.value;
    const positions = this.positions(component);
    const revision = -1;

    const data = { text, positions, font, color, fontSize, id, revision };
    annotation.initialize(data);

    // console.log(id, annotation, data)

    return annotation;
  }

  createSignature(component: any) {
    const signature = new Signature();
    const dataType = isImage(component.params.value) ? "image" : "text";
    const type = "signature";
    const value = component.params.value;
    const font = this.font(component.params.fontFamily || "");
    const timestamp = component.params.timestamp;
    const id = `${signature.className}-${component.id}`;
    const positions = this.positions(component);
    const revision = -1;

    const data = {
      type,
      data: value,
      dataType,
      positions,
      font,
      timestamp,
      id,
      revision,
    };
    signature.initialize(data);

    // console.log(id, signature, data)

    return signature;
  }

  positions(component) {
    const {
      params: { x, y },
    } = component;

    const pageNo = this.pageNo(component);
    const page = this.page(pageNo);

    const pageX = x;
    const pageY = y - this.pageHeight * (pageNo - 1) - (pageNo - 1) * 13;

    // console.log(pageX, pageY, page.getBoundingClientRect())

    return getPositionPercentage(pageX, pageY, page, false);
  }

  page(pageNo) {
    return this.pages[pageNo - 1];
  }

  pageNo(component) {
    let pageNo = 0;

    const {
      params: { page, y },
    } = component;

    // pageNo = component.page || page || 0

    pageNo = 0;

    if (pageNo === 0) {
      const actualHeight = y - Math.floor(y / this.pageHeight) * 13;
      pageNo = Math.ceil(actualHeight / this.pageHeight);
      // console.log(y, actualHeight, this.pageHeight, pageNo)
    }

    // console.log(pageNo, this.pageHeight)

    return pageNo;
  }

  font(styleName = "") {
    let font = null;
    font = this.fontCollectionService.getFont(styleName);
    if (!font) {
      font = this.fontCollectionService.defaultFont();
    }
    return font;
  }

  type(component: any) {
    let type = "";
    if (component.params.fontFamily) {
      type = ANNOTATION;
    }
    if (component.params.timestamp) {
      type = SIGNATURE;
    }
    return type;
  }
}
