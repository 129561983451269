import { IActionType } from "src/types/event"
import { jsonData } from "src/utils/general"
import { AbstractPageItem } from "./abstract-page-item"

export class Action {
    public type: IActionType
    public item: AbstractPageItem
    public pageId: string
    public change?: any

    initialize(type: IActionType, item: AbstractPageItem, pageId: string, change?: any) {
        this.type = type
        this.item = item
        this.pageId = pageId
        this.change = change

        return this
    }

    public jsonData() {
        return jsonData(this)
    }
}