import { PAGE_FOCUS, SAVE_AND_DOWNLOAD_PDF, SAVE_PDF } from "src/constants";
import { Highlight } from "src/lib/highlight";
import { Page } from "src/lib/page";
import { IEvent } from "src/types/event";
import { addStyles, createTemplate, removeElements, respectivePosition } from "src/utils/dom";
import { AbstractTool } from "../abstract-tool";

export class Tool extends AbstractTool {
    public id;
    public className;

    public page
    private root

    // handlers
    private dragHandler;
    private dragStartHandler;
    private dragEndHandler;
    private clickHandler;

    private x = 0
    private y = 0
    private highlight: Highlight = null

    constructor(private pageService: Page) {
        super()

        this.actionService = this.pageService.editor.actionService

        this.id = `editor-page-highlight-cursor-${this.pageService.getId()}`
        this.className = 'editor-page-highlight-cursor'

        this.root = this.pageService.getRoot()
        this.page = this.pageService.getPage()

        // Bindings
        this.bindHandlers();
    }

    public initialize() {
        this.page.addEventListener('click', this.clickHandler)

        return this
    }

    handleClick(event) {
        const brush = this.create()

        const positions = respectivePosition(event, this.page)

        const media = this.positionStyles(positions)
        addStyles(brush.id, media, this.root)

        // Hide other brush
        this.pageService.editor.broadcast({ type: PAGE_FOCUS, data: { page: { id: this.pageService.getId() } } })
    }

    handleDragStart(event: DragEvent) {
        const { x: eventX, y: eventY } = event

        this.x = eventX
        this.y = eventY

        const highlight = this.highlight = new Highlight()

        highlight.initialize({ revision: this.pageService.editor.revision })

        this.highlight = highlight

        this.pageService.createItem(this.highlight)

        this.createAction(null, highlight)
    }

    handleDrag(event) {
        const { x: eventX, y: eventY } = event

        this.x = eventX
        this.y = eventY

        const position = respectivePosition(event, this.page);
        this.highlight.highlight({ x: position.screen.x, y: position.screen.y }, this.page)
    }

    handleDragEnd(event) {
        const { x: eventX, y: eventY } = event

        this.x = eventX
        this.y = eventY

        this.highlight = null

        const brush = this.element()
        const positions = respectivePosition(event, this.page)

        const media = this.positionStyles(positions)
        addStyles(brush.id, media, this.root)
    }

    handlePageFocus(event) {
        const { data } = event

        if (data.page.id !== this.page.id) {
            this.hideBrush()
        }
    }

    handleSave(event) {
        this.hideBrush()
    }

    hideBrush() {
        const brush = this.element()
        if (brush) {
            const media = { screen: { display: 'none' } }
            addStyles(brush.id, media, this.root)
        }
    }

    create() {
        let brush = this.element()

        if (brush) return brush

        // Create
        const template = createTemplate(this.template())
        this.page.appendChild(template.content)

        // Bind events
        brush = this.element()
        this.toggleEventListners(brush)

        // Add brush styles
        addStyles(`${this.className}-general`, this.styles(), this.root)

        return brush
    }

    template() {
        return `<div id="${this.id}" class="${this.className}" draggable="true">&nbsp;</div>`
    }

    element() {
        return this.page.querySelector(`.${this.className}`)
    }

    styles() {
        return `
            .${this.className} {
                background-color: lightgray;
                border: 2px solid gray;
                border-radius: 3px;
                z-index: 50;
                display: block;
                position: absolute;
                width: 26px;
                height: 30px;
                cursor: pointer;
            }
            
            @media print {
                .${this.className}, .hide-in-print {
                    display: none !important;
                }
            }
        `
    }

    positionStyles(positions) {
        return {
            screen: Object.assign({}, {
                left: `${positions.screen.x}%`,
                top: `${positions.screen.y}%`
            }),
            print: Object.assign({}, {
                left: `${positions.print.x}%`,
                top: `${positions.print.y}%`
            })
        }
    }

    bindHandlers() {
        this.clickHandler = this.handleClick.bind(this)

        this.dragHandler = this.handleDrag.bind(this)
        this.dragStartHandler = this.handleDragStart.bind(this)
        this.dragEndHandler = this.handleDragEnd.bind(this)
    }

    // Events
    toggleEventListners(brush, remove = false) {
        const method = remove ? 'removeEventListener' : 'addEventListener'

        brush[method]('dragstart', this.dragStartHandler)

        brush[method]('drag', this.dragHandler)

        brush[method]('dragend', this.dragEndHandler)
    }

    public broadcast(event: IEvent) {
        const { type } = event

        // Handle events
        if (type === PAGE_FOCUS) {
            this.handlePageFocus(event)
        }

        if (type === SAVE_PDF || type === SAVE_AND_DOWNLOAD_PDF) {
            this.handleSave(event)
        }
    }

    public cleanup(dom) {
        const identifiers = [
            `.${this.className}`,
            `#${this.className}-general`,
            `#${this.id}-screen`,
            `#${this.id}-print`,
        ]

        for (const identifier of identifiers) {
            removeElements(identifier, dom)
        }
    }

    public destroy() {
        this.page.removeEventListener('click', this.clickHandler)

        const brush = this.element()
        if (brush) {
            this.toggleEventListners(brush, true)
            brush.remove()
        }

        this.cleanup(this.root)
    }
}