import {
  Component,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventEmitter } from "@angular/core";
import { IToolChange } from "src/types/event";
import {
  SAVE_AND_DOWNLOAD_PDF,
  SAVE_PDF,
  TOOL_CHANGE,
  UNDO,
} from "src/constants";

@Component({
  selector: "toolbar-component",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class ToolbarComponent implements OnChanges {
  @Input() isEditorReady: boolean;

  @Output() onToolChange: EventEmitter<IToolChange> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onUndo: EventEmitter<any> = new EventEmitter();

  public tool: string = "signature";
  public tooltipOptions = {
    placement: "bottom",
    "show-delay": 500,
  };
  public mode: any;

  ngOnInit() {
    this.mode = localStorage.getItem("mode");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isEditorReady.currentValue) return;
    this.changeTool("signature", this.tool);
  }

  public changeTool(tool, payload = null) {
    if (tool === "annotation") {
      this.tool = tool;
    } else {
      this.tool = payload;
    }
    if (payload || this.tool === "annotation")
      this.onToolChange.emit({ type: TOOL_CHANGE, data: { tool, payload } });
  }

  public undo() {
    this.onUndo.emit({ type: UNDO, data: null });
  }

  public save(download = false) {
    download
      ? this.onSave.emit({ type: SAVE_AND_DOWNLOAD_PDF, data: null })
      : this.onSave.emit({ type: SAVE_PDF, data: null });
  }
}
