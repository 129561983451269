import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public url: string;

  constructor(
    private readonly http: HttpClient
  ) { }

  public getData(token: string) {
    return this.http.get(`${this.url}/jwt/get_data.php?token=${token}`, { observe: 'response' });
  }

  public getFile(token: string) {
    return this.http.get(`${this.url}/jwt/get_file.php?token=${token}`, { responseType: `blob` });
  }

  public getHtmlFromBase64Pdf(token: string, file: string | ArrayBuffer) {
    return this.http.post(`${environment.url}/pdfToHtml`, { file, scale: 1.78 });
  }

  public sendHtml(html: string, id: string, token: string) {
    return this.http.post(`${environment.url}/htmlToPdf`, { id, html, token }, { responseType: `blob` });
  }

  public sendPdfToMainServer(token: string, form: FormData) {
    return this.http.post(`${this.url}/jwt/set_file.php?token=${token}`, form);
  }
}
