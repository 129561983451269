import { Injectable } from "@angular/core";
import { lowerCase } from "lodash";
import { IFont } from "src/types/event";

@Injectable()
export class FontCollection {
    public fonts: IFont[] = [
        { name: 'Material Icons', id: 'material', styleName: '', selectable: false, withFontFace: true, url: 'https://fonts.googleapis.com/icon?family=Material+Icons' }
    ]

    constructor() {
        this.fonts.push({ name: 'Roboto', id: 'roboto', styleName: 'Roboto', selectable: true, withFontFace: true, url: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap' })
        this.fonts.push({ name: 'Times New Roman', id: "times-new-roman-mt", styleName: 'Times New Roman', selectable: true, withFontFace: true, url: `/assets/fonts/font-times-new-roman.css` })
        this.fonts.push({ name: 'Amazon BT', id: "amazon-bt-regular", styleName: 'Amazone BT', selectable: true, withFontFace: true, url: `/assets/fonts/font-amazon-bt.css` })
        this.fonts.push({ name: 'Freehand', id: 'freehand', styleName: 'freehand', selectable: true, withFontFace: true, url: 'https://fonts.googleapis.com/css2?family=Freehand&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;1,100;1,200;1,400&display=swap' })
        this.fonts.push({ name: 'Comic Sans', id: "comic-sans-ms", styleName: 'Comic Sans MS', selectable: true, withFontFace: true, url: `/assets/fonts/font-comicsans.css` })
    }

    public getFonts(): IFont[] {
        return this.fonts
    }

    public getSelectableFonts(): IFont[] {
        return this.fonts.filter(font => font.selectable)
    }

    public defaultFont(): IFont {
        return this.fonts[1]
    }

    public getFont(id: string): IFont {
        return this.fonts.find(font => (font.id === id || (font.styleName && (lowerCase(id)).includes(lowerCase(font.styleName)))))
    }
}