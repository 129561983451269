export const jsonData = (instance, ignore = []) => {
    const jsonData: any = {}
    for (const property in instance) {
        if (ignore.includes(property)) {
            continue;
        }

        let value: any = instance[property]
        if (value && typeof value.jsonData === 'function') {
            value = value.jsonData()
        }

        jsonData[property] = value
    }
    return jsonData;
}

export const isImage = (data) => {
    return data.startsWith("data:image/");
}

export const getAllPages = (container: HTMLElement) => {
    if (!container) return []
    return container.querySelectorAll('div[data-page-no]')
}

export const pixelsToPdfFormat = (width: number, height: number) => {
    let format = 'A4'
    let landscape = false

    if (inBetween(width, [792, 795]) && inBetween(height, [1121, 1124])) {
        format = 'A4'
    }

    if (inBetween(width, [1121, 1124]) && inBetween(height, [792, 795])) {
        format = 'A4'
        landscape = true
    }

    if (inBetween(width, [814, 817]) && inBetween(height, [1055, 1060])) {
        format = 'Letter'
    }

    if (inBetween(width, [1055, 1060]) && inBetween(height, [814, 817])) {
        format = 'Letter';
        landscape = true;
    }

    if (inBetween(width, [792, 795]) && inBetween(height, [558, 561])) {
        format = 'A5'
        landscape = true
    }

    return { format, landscape }
}

export const inBetween = (number: number, range: number[]) => {
    return number >= range[0] && number <= range[1]
}

export const formatFromDimensions = (width, height) => {
    const scale = 1.78;
    const ptToPxFactor = 1.333333;

    const widthPixels = (width / scale) * ptToPxFactor;
    const heightPixels = (height / scale) * ptToPxFactor;

    return pixelsToPdfFormat(widthPixels, heightPixels)
}

export default { jsonData, isImage, pixelsToPdfFormat, inBetween, formatFromDimensions, getAllPages }