import { isEmpty } from "lodash";
import {
  ANNOTATION,
  SAVE_PDF,
  TOOL_CHANGE,
  SIGNATURE,
  HIGHLIGHT,
  SAVE_AND_DOWNLOAD_PDF,
} from "src/constants";
import { IActionType, IEvent } from "src/types/event";
import {
  addStyles,
  appendElement,
  createStyleElement,
  createTemplate,
  removeElements,
  respectiveFontSize,
  respectivePercentage,
  respectivePosition,
  touchrespectivePosition,
  widthHeightPercentageChange,
  touchwidthHeightPercentageChange,
} from "src/utils/dom";
import { Annotation } from "./annotation";
import { Signature } from "./signature";
import { Editor } from "./editor";
import { Tool as AnnotationTool } from "./tools/annotation/tool";
import { Tool as SignatureTool } from "./tools/signature/tool";
import { Tool as HighlightTool } from "./tools/highlight/tool";
import { AbstractPageItem } from "./abstract-page-item";
import { Highlight } from "./highlight";
import { Action } from "./action";

export class Page {
  public editor: Editor;
  public page: Element;
  public container: Element;
  public activeTool;
  public touchStartX = 0;
  public touchStartY = 0;
  public elementStartX = 0;
  public elementStartY = 0;
  public stopTouchDrag: Boolean = false;
  private allPages: any[] = [];
  public scanStyles = {
    screen: ["font-family", "color", "font-size", "box-shadow"],
    print: ["font-family", "color", "font-size"],
  };
  private change;
  public dragHandleClass = "editor-page-item-drag-handle";
  public deleteHandleClass = "editor-page-item-delete-handle";
  public resizeHandleClass = "editor-page-item-resize-handle";
  public copyHandleClass = "editor-page-item-copy-handle";

  private items: AbstractPageItem[] = [];
  private test;
  // Event Handlers
  private deleteHandler;
  private copyHandler;
  constructor(page, container, editor, initialItems, test) {
    this.page = page;
    this.container = container;
    this.editor = editor;
    this.test = test;
    this.deleteHandler = (event) => {
      this.handleDelete(event);
    };

    // this.initialize(initialItems);
  }

  // initialize(initialItems = []) {
  //   let items: AbstractPageItem[] = [];

  //   for (const initialItem of initialItems) {
  //     let item = null;
  //     if (initialItem.itemType === ANNOTATION) {
  //       item = new Annotation();
  //     }
  //     if (initialItem.itemType === SIGNATURE) {
  //       item = new Signature();
  //     }
  //     if (initialItem.itemType === HIGHLIGHT) {
  //       item = new Highlight();
  //     }

  //     if (!item) continue;

  //     item.initialize(initialItem);
  //     items.push(item);
  //   }

  //   this.items = items;

  //   // Append drag, remove handle
  //   items.forEach((item) => {
  //     const element = item.element();

  //     if (!element) {
  //       this.createItem(item, false);
  //     }
  //     if (item.checkEditable && !item.isEditable(this.editor.revision)) return;

  //     this.addHandles(item);
  //   });
  // }

  public addHandles(item: AbstractPageItem) {
    const element = item.element();

    if (item.isMovable) {
      this.addDragHandle(element);
    }

    if (item.isRemovable) {
      this.addDeleteHandle(element);
    }

    if (item.isResizable) {
      this.addResizeHandle(element, item);
    }
    if (item.isCopy && item.type === "initial") {
      this.addCopyHandle(element, item);
    }
  }

  public addDragHandle(element: Element) {
    const id = `${element.id}-drag-handle`;
    const item = this.getItem(element.id) as any;
    if (!item) return;
    const dragTemplate = this.dragHandle(id);
    element.appendChild(dragTemplate.content);
    const dragElement: Element = element.querySelector(`#${id}`);
    if (!dragElement) return;

    element.addEventListener("dragend", (event: DragEvent) => {
      const oldStates = item.jsonData();
      const positions = respectivePosition(event, this.page);

      item.positions = positions;

      this.createStyles(item);

      const newStates = item.jsonData();

      const change = {
        oldStates: { ...oldStates },
        newStates: { ...newStates },
      };

      const action = new Action();
      action.initialize(IActionType.EDIT, item, this.page.id, change);
      this.editor.actionService.addAction(action);
    });

    dragElement.addEventListener("touchstart", (event: TouchEvent) => {
      event.stopPropagation();
      event.preventDefault();
    });

    dragElement.addEventListener("touchend", (event) => {
      const oldStates = item.jsonData();

      const positions = touchrespectivePosition(event, this.page);
      item.positions = positions;

      this.createStyles(item);

      const newStates = item.jsonData();

      const change = {
        oldStates: { ...oldStates },
        newStates: { ...newStates },
      };

      const action = new Action();
      action.initialize(IActionType.EDIT, item, this.page.id, change);
      this.editor.actionService.addAction(action);
    });
  }

  public addDeleteHandle(element: Element) {
    const id = `${element.id}-delete-handle`;

    const removeTemplate = this.deleteHandle(id);
    element.appendChild(removeTemplate.content);

    const removeElement = element.querySelector(`#${id}`);
    if (removeElement) {
      removeElement.removeEventListener("click", this.deleteHandler);
      removeElement.addEventListener("click", this.deleteHandler);
    }
  }
  public imageOrText(data) {
    return data.startsWith("data:image/") ? "image" : "text";
  }

  public addCopyHandle(element: Element, item) {
    const copyElementId = `${element.id}-copy-handle`;

    const copyTemplate = this.copyHandle(copyElementId);
    element.appendChild(copyTemplate.content);

    const copyElement: Element = element.querySelector(`#${copyElementId}`);
    const parent = copyElement.parentNode as Element;
    const copyElementClickHandler = () => {
      localStorage.setItem("initial-placement", JSON.stringify(item.positions));
      const imgDiv: any = parent.querySelector(".image");
      const textDiv: any = parent.querySelector("svg");

      if (imgDiv && !textDiv) {
        const imgElement = imgDiv.querySelector("img");
        const srcValue = imgElement.src;

        let payload = {
          data: srcValue,
          fontFamily: "Roboto",
          timeStamp: "",
          type: "initial",
          dataType: "image",
          signData: {},
          font: {
            id: "roboto",
            name: "Roboto",
            selectable: true,
            styleName: "Roboto",
            url: "https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap",
            withFontFace: true,
          },
          clone: true,
          cloneID: element.id.split("-")[4],
          currentPage: this.page.id,
        };

        this.test(payload);
      } else if (!imgDiv && textDiv) {
        const textElement = textDiv.querySelector("text");

        let payload = {
          data: textElement.innerHTML,
          fontFamily: "Roboto",
          timeStamp: "",
          type: "initial",
          dataType: "text",
          font: {
            id: "roboto",
            name: "Roboto",
            selectable: true,
            styleName: "Roboto",
            url: "https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap",
            withFontFace: true,
          },
          clone: true,

          currentPage: this.page.id,
        };

        this.test(payload);
      }
    };

    // Add the event listener to the original element
    copyElement.addEventListener("click", copyElementClickHandler);
  }

  public addResizeHandle(element, item: AbstractPageItem) {
    const id = `${element.id}-resize-handle`;

    const resizeTemplate = this.resizeHandle(id);
    element.appendChild(resizeTemplate.content);

    const resizeElement: Element = element.querySelector(`#${id}`);
    if (resizeElement) {
      let rect = resizeElement.getBoundingClientRect();
      let x = rect.left;
      let y = rect.top;

      resizeElement.addEventListener("dragstart", (event: DragEvent) => {
        const { x: eventX, y: eventY } = event;

        x = eventX;
        y = eventY;
      });
      resizeElement.addEventListener("dragend", (event: DragEvent) => {
        event.preventDefault();
        event.stopImmediatePropagation();

        const { x: clientX, y: clientY } = event;

        const oldStates = item.jsonData();

        item.handleResize(
          event,
          widthHeightPercentageChange(event, this.page, x, y)
        );

        const newStates = item.jsonData();

        x = clientX;
        y = clientY;

        this.createStyles(item);

        const change = {
          oldStates: { ...oldStates },
          newStates: { ...newStates },
        };

        const action = new Action();
        action.initialize(IActionType.EDIT, item, this.page.id, change);
        this.editor.actionService.addAction(action);
        this.editor.updateSignatureSave(item);
      });

      resizeElement.addEventListener("touchmove", (event: TouchEvent) => {
        event.preventDefault();
        event.stopImmediatePropagation();

        const touch = event.touches[0];
        const { clientX, clientY } = touch;

        const oldStates = item.jsonData();

        item.handleResize(
          event,
          touchwidthHeightPercentageChange(
            event,
            this.page,
            x,
            y,
            resizeElement
          )
        );

        const newStates = item.jsonData();

        x = clientX;
        y = clientY;

        this.createStyles(item);

        const change = {
          oldStates: { ...oldStates },
          newStates: { ...newStates },
        };
        this.change = change;
        // const action = new Action();
        // action.initialize(IActionType.EDIT, item, this.page.id, change);
        // this.editor.actionService.addAction(action);
        // this.editor.updateSignatureSave(item);
      });

      resizeElement.addEventListener("touchstart", (event: TouchEvent) => {
        event.stopPropagation();
        event.preventDefault();

        const { clientX: eventX, clientY: eventY } = event.touches[0];
        x = eventX;
        y = eventY;
      });
      resizeElement.addEventListener("touchend", (event: TouchEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const action = new Action();
        action.initialize(IActionType.EDIT, item, this.page.id, this.change);
        this.editor.actionService.addAction(action);
        this.editor.updateSignatureSave(item);
      });

      // resizeElement.addEventListener("touchmove", (event: TouchEvent) => {
      //   event.preventDefault();
      //   event.stopImmediatePropagation();

      //   const touch = event.touches[0];

      //   const oldStates = item.jsonData();

      //   item.handleResize(
      //     event,
      //     touchwidthHeightPercentageChange(
      //       event,
      //       this.page,
      //       x,
      //       y,
      //       resizeElement
      //     )
      //   );

      //   const newStates = item.jsonData();
      //   const { clientX: eventX, clientY: eventY } = event.touches[0];

      //   this.createStyles(item);

      //   const change = {
      //     oldStates: { ...oldStates },
      //     newStates: { ...newStates },
      //   };

      //   const action = new Action();
      //   action.initialize(IActionType.EDIT, item, this.page.id, change);
      //   this.editor.actionService.addAction(action);
      //   this.editor.updateSignatureSave(item);
      // });
    }
  }

  // Handle Events

  public handleDelete(event: any) {
    const element =
      event.target.closest(`.${Annotation.identifier}`) ||
      event.target.closest(`.${Signature.identifier}`);
    if (!element) return;

    const item = this.getItem(element.id);
    this.removeItem(item, element);

    if (item) {
      const action = new Action();
      action.initialize(IActionType.DELETE, item, this.page.id);
      this.editor.actionService.addAction(action);
    }
  }

  public handleSave(event: IEvent) {
    this.cleanup(event.data.dom);

    if (this.activeTool) {
      this.activeTool.cleanup(event.data.dom);
    }
  }

  public removeListeners(element: Element) {
    if (!element) return;

    element.removeEventListener("drag", this.deleteHandler);

    const deleteHandle = element.querySelector(`.${this.deleteHandleClass}`);
    if (deleteHandle) {
      deleteHandle.removeEventListener("click", this.deleteHandler);
    }

    const resizeHandle = element.querySelector(`.${this.resizeHandleClass}`);
    if (resizeHandle) {
      resizeHandle.removeEventListener("click", this.deleteHandler);
    }
    const copyHandle = element.querySelector(`.${this.copyHandleClass}`);
    if (copyHandle) {
      copyHandle.removeEventListener("click", this.copyHandler);
    }
  }

  public removeStyles(styleIds: string[]) {
    for (const styleId of styleIds || []) {
      const element = this.getRoot().querySelector(styleId);
      if (!element) continue;
      element.remove();
    }
  }

  dragHandle(id = "") {
    const style = `position:absolute;display:flex;top:-20px;left:-9px;cursor:move;`;
    return createTemplate(`
            <div id="${id}" class="${this.dragHandleClass}" style="${style}">
                <i class="material-icons" style="font-size:20px;color:#2178d6;">
                    open_with
                </i>
            </div>
        `);
  }

  deleteHandle(id = "") {
    const style = `position:absolute;display:flex;top:-20px;right:0;cursor:pointer;
        padding: 2px;`;
    return createTemplate(`
            <div id="${id}" class="${this.deleteHandleClass}" style="${style}">
               <img src="assets/icon/icon-trash.svg" />
            </div>
        `);
  }

  resizeHandle(id = "") {
    const style = `position:absolute;display:flex;top:100%;right:0;cursor:se-resize;`;
    return createTemplate(`
            <div id="${id}" class="${this.resizeHandleClass}" draggable="true" style="${style}">
                <i class="material-icons" style="font-size:20px;color:#2178d6">
                    radio_button_checked
                </i>
            </div>
        `);
  }
  copyHandle(id = "") {
    const style = `position:absolute;display:flex;top:100%;left:0;cursor:pointer;`;
    return createTemplate(`
            <div id="${id}" class="${this.copyHandleClass}" draggable="true" style="${style}">
                <i class="material-icons" style="font-size:20px;color:#2178d6">
                   content_copy
                </i>
            </div>
        `);
  }

  createItem(item: AbstractPageItem, withHandles = true) {
    try {
      const template = item.template();

      const existingElement = item.element(this.page);
      if (existingElement) {
        this.removeListeners(existingElement);
        this.removeStyles(item.styleIds());
        existingElement.remove();
      }

      appendElement(this.page, template.content, true);
      this.createStyles(item);

      item.hookAfter();

      this.items = this.items.filter((pageItem) => pageItem.id !== item.id);
      this.items.push(item);

      if (withHandles) {
        this.addHandles(item);
      }
    } catch (err) {
      console.error(err);
    }
  }

  createStyles(item: AbstractPageItem) {
    const editable = item.checkEditable
      ? item.isEditable(this.editor.revision)
      : true;
    const styles = item.styles(editable);

    for (const style of styles) {
      appendElement(this.getRoot(), style, true);
    }
  }

  removeItem(item: AbstractPageItem, existingElement = null) {
    if (!item) return;

    const element = existingElement || item.element();

    this.items = this.items.filter((pageItem) => pageItem.id !== item.id);
    this.removeStyles(item.styleIds());

    if (!element) return;
    this.removeListeners(element);
    element.remove();
  }

  getId() {
    return this.page.id;
  }

  getPage() {
    return this.page;
  }

  getRoot(): Element {
    return this.editor.root;
  }

  getEditor(): Editor {
    return this.editor;
  }

  getItems() {
    return this.items;
  }

  getItem(id) {
    return this.items.find((item) => item.id === id);
  }

  broadcast(event: IEvent, pages) {
    const { type } = event;

    // Handle events
    if (type === TOOL_CHANGE) {
      this.handleToolChange(event, pages);
    }

    if (type === SAVE_PDF || type === SAVE_AND_DOWNLOAD_PDF) {
      this.handleSave(event);
    }

    // Broadcast
    if (this.activeTool) {
      this.activeTool.broadcast(event);
    }
  }

  handleToolChange(event: IEvent, pages) {
    pages.forEach((item) => {
      this.allPages.push(item.page);
    });
    const {
      data: { tool },
    } = event;
    if (tool === this.activeTool) return;

    if (this.activeTool) this.activeTool.destroy();

    if (tool === ANNOTATION) {
      this.activeTool = new AnnotationTool(this);
    }

    if (tool === SIGNATURE) {
      const { payload } = event.data;

      this.activeTool = new SignatureTool(this, payload, this.allPages);
    }

    if (tool === HIGHLIGHT) {
      this.activeTool = new HighlightTool(this);
    }

    this.activeTool.initialize();
  }

  public cleanup(dom) {
    const identifiers = [
      `.${this.deleteHandleClass}`,
      `.${this.dragHandleClass}`,
      `.${this.resizeHandleClass}`,
      `.${this.copyHandleClass}`,
    ];
    for (const identifier of identifiers) {
      removeElements(identifier, dom);
    }
  }

  public destroy() {
    (this.items || []).forEach((item) => {
      const element = item.element();
      this.removeListeners(element);
    });
  }
}
