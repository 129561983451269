import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  Input,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { FileService } from "src/services/file.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularDropdownDirective } from "angular-dropdown";
import { ToastrService } from "ngx-toastr";
import { IToolChange } from "src/types/event";
import { FontCollection } from "src/lib/services/font-collection";
import { isArray } from "lodash";

@Component({
  selector: "app-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
})
export class SignatureComponent implements OnInit {
  @ViewChild("textArea", { static: false }) textArea: ElementRef;
  @ViewChild("dropdown", { static: false }) dropdown: AngularDropdownDirective;
  @Input() tool: string;
  @Output() changeTool: EventEmitter<IToolChange> = new EventEmitter();

  fontOptions;

  public tooltipOptions = {
    placement: "bottom",
    "show-delay": 500,
  };
  public signData;
  public showPopup: boolean = true;
  public isToggled = false;

  public signatureImg: boolean = false;
  public initialImg: boolean = false;

  public isSignature: boolean = false;
  public isInitial: boolean = false;

  public textModePopup: boolean = false;
  public canvasModePopup: boolean = false;
  public imageUpload: boolean = false;

  public isSave: boolean = true;
  public signatureImage: any;
  public isValue: boolean = false;

  public changeText: boolean = false;
  public fontFamily = "Roboto";

  public sigPadElement: any;
  public contexte: any;
  public isDrawing: boolean = false;
  public imge: any;
  public context: any;
  public showPlaceholder: boolean = true;
  errorMsg: string;
  img: any;
  touchX: any;
  touchY: any;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly fileService: FileService,
    private readonly toastr: ToastrService,
    private modalService: NgbModal,
    private fontCollectionService: FontCollection
  ) {}
  toggle(content2) {
    if (Object.keys(this.signData.signature).length === 0) {
      this.isSignature = true;
      this.modalService.open(content2, { size: "sm", centered: true });
      this.textModePopup = true;
      this.canvasModePopup = false;
      this.imageUpload = false;
    } else {
      this.isToggled = !this.isToggled;
    }
  }
  ngOnInit() {
    this.fontOptions = this.fontCollectionService.getSelectableFonts();

    this.route.queryParams.subscribe((params: Params) => {
      const { token } = params;
      if (!token) return;

      this.fileService.loadData(token).then((response: any) => {
        if (!response.body) return;
        this.signData = response.body || {
          mode: "TT",
          signature: { value: "", fontFamily: "" },
          initial: { value: "", fontFamily: "" },
          first_name: "",
          surname: "",
        };

        if (
          isArray(this.signData.signature) ||
          this.signData.signature == null
        ) {
          this.textModePopup = true;
          this.signData.signature = {};
        }

        if (isArray(this.signData.initial) || this.signData.initial == null) {
          this.signData.initial = {};
        }

        if (
          this.signData &&
          this.signData.signature &&
          this.signData.signature.value
        ) {
          this.signatureImg =
            this.signData.signature.value.startsWith("data:image/");
          this.useSignature(this.signData.signature, "signature");
        }

        if (
          this.signData &&
          this.signData.initial &&
          this.signData.initial.value
        ) {
          this.initialImg =
            this.signData.initial.value.startsWith("data:image/");
        }

        localStorage.setItem(
          "signature-height",
          this.signData.signature.height
        );
        localStorage.setItem("signature-width", this.signData.signature.width);

        localStorage.setItem("initial-height", this.signData.initial.height);
        localStorage.setItem("initial-width", this.signData.initial.width);
      });
    });
    // this.dropdown.open();
  }

  public fontChanged(event) {
    const styleName = event.target.value;
    this.applyFontFamily(styleName);
  }

  public removeSignature(type) {
    if (type == "signature") {
      Object.keys(this.signData.signature).forEach(
        (signature) => delete this.signData.signature[signature]
      );
      this.signatureImg = false;
    }

    if (type == "initial") {
      Object.keys(this.signData.initial).forEach(
        (initial) => delete this.signData.initial[initial]
      );
      this.initialImg = false;
    }
  }

  freeHand() {
    this.showPopup = !this.showPopup;
  }

  public open(content2, value) {
    if (value == "signature") {
      this.isSignature = true;
    } else if (value == "initial") {
      this.isInitial = true;
    }

    this.modalService.open(content2, { size: "sm", centered: true });

    this.textModePopup = true;
    this.canvasModePopup = false;
    this.imageUpload = false;
  }

  closePopup() {
    this.isToggled = false;
    this.textModePopup = false;
    this.canvasModePopup = false;
    this.imageUpload = false;
    this.isValue = false;
    this.signatureImage = null;
    this.isSignature = false;
    this.isInitial = false;
    this.modalService.dismissAll();
  }

  addTextModePopup(e) {
    this.textModePopup = true;
    this.canvasModePopup = false;
    this.imageUpload = false;
    this.isSave = true;
    this.signatureImage = null;
  }

  addCanvasPopup(e) {
    this.canvasModePopup = true;
    this.textModePopup = false;
    this.imageUpload = false;
    this.isSave = true;
    this.signatureImage = null;
    this.showPlaceholder = true;
  }

  imageUploadSign(e) {
    this.imageUpload = true;
    this.canvasModePopup = false;
    this.textModePopup = false;
    this.isSave = true;
  }

  textAreaClick(e) {
    this.isValue = true;
  }

  public applyFontFamily(fontFamily) {
    this.fontFamily = fontFamily;
  }

  public changeAreaSize(value) {
    this.changeText = true;
  }

  onCheckboxChange(e) {
    if (e.target.checked == true) this.isSave = true;
    else this.isSave = false;
  }

  @HostListener("document:mouseup", ["$event"])
  onMouseUp(e) {
    this.isDrawing = false;
  }
  onTouchEnd(e) {
    this.isDrawing = false;
  }
  onTouchStart(e) {
    this.getcanvas();
    this.isDrawing = true;
    this.touchCoord(e);
    this.context.moveTo(this.touchX, this.touchY);
  }

  onTouchMove(e) {
    if (this.isDrawing) {
      const coords = this.touchCoord(e);
      this.touchCoord(e);
      this.context.lineTo(this.touchX, this.touchY);
      this.context.stroke();
      this.isValue = true;
    }
  }
  private touchCoord(e: TouchEvent) {
    if (e.touches && e.touches.length === 1) {
      const touch = e.touches[0];
      const canvasRect = this.sigPadElement.getBoundingClientRect();
      this.touchX = touch.clientX - canvasRect.left;
      this.touchY = touch.clientY - canvasRect.top;
    }
  }

  getcanvas() {
    this.sigPadElement = <HTMLCanvasElement>document.getElementById("canvas");
    this.context = this.sigPadElement.getContext("2d");
    this.context.strokeStyle = "#000000";
    this.showPlaceholder = false;
  }

  onMouseDown(e) {
    this.getcanvas();
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
      this.isValue = true;
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  onFileSelect(e) {
    if (!e.target.files[0] || e.target.files[0].length == 0) {
      this.errorMsg = "You must select an image";
      return;
    }

    var mimeType = e.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.errorMsg = "Only images are supported";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      this.errorMsg = "";
      this.signatureImage = reader.result;
      this.isValue = true;
    };
  }

  formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    var style = "style='width:max-content;'";
    return `<p ${style}>
    ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}-${strTime}
    <br/>
    Digitally Signed by:
    <br/>
    ${this.signData.first_name} ${this.signData.surname}
    </p>`;
  }

  useSignature(signature, type) {
    const { value, fontFamily } = signature;
    const d = new Date();
    const time = this.formatDate(d);

    const dataType = this.imageOrText(value);

    const payload = {
      type,
      data: value,
      dataType,
      fontFamily,
      timestamp: time,
    };

    this.saveSignature(payload);
    this.isToggled = false;
  }

  public applySignature(value: string) {
    if (!this.isValue) {
      this.toastr.warning("Please add Signature");
      return;
    }

    var d = new Date();
    var time = this.formatDate(d);

    let payload = {
      value: value,
      timestamp: time,
      fontFamily: this.fontFamily,
      signature: false,
      initial: false,
      type: "",
      dataType: "",
      data: "",
      save: this.isSave,
      signData: this.signData,
      clone: false,
    };

    if (value == "canvas") {
      this.img = this.sigPadElement.toDataURL("image/png");
      value = this.img;
      payload.value = this.img;
      delete payload.fontFamily;
      if (this.isSignature && this.isSave) {
        this.signatureImg = true;
        if (this.signData.signature.fontFamily)
          delete this.signData.signature.fontFamily;
        this.signData.signature.value = value;
        payload.signature = true;
        payload.type = "signature";
      } else if (this.isInitial && this.isSave) {
        this.initialImg = true;
        if (this.signData.initial.fontFamily)
          delete this.signData.initial.fontFamily;
        this.signData.initial.value = value;
        payload.initial = true;
        payload.type = "initial";
      }
    } else if (value == "imageUpload") {
      value = this.signatureImage;
      payload.value = value;
      delete payload.fontFamily;
      if (this.isSignature && this.isSave) {
        this.signatureImg = true;
        if (this.signData.signature.fontFamily)
          delete this.signData.signature.fontFamily;
        this.signData.signature.value = value;
        payload.signature = true;
        payload.type = "signature";
      } else if (this.isInitial && this.isSave) {
        this.initialImg = true;
        if (this.signData.initial.fontFamily)
          delete this.signData.initial.fontFamily;
        this.signData.initial.value = value;
        payload.initial = true;
        payload.type = "initial";
      }
    } else {
      if (this.isSignature && this.isSave && value != null) {
        this.signData.signature.value = value;
        this.signData.signature.fontFamily = this.fontFamily;
        payload.signature = true;
        payload.type = "signature";
      } else if (this.isInitial && this.isSave && value != null) {
        this.signData.initial.value = value;
        this.signData.initial.fontFamily = this.fontFamily;
        payload.initial = true;
        payload.type = "initial";
      }
    }

    payload.type = this.isSignature ? "signature" : "initial";
    payload.data = payload.value;
    payload.dataType = value.startsWith("data:image/") ? "image" : "text";
    payload.clone = false;
    payload.save = this.isSave;
    payload.signData = this.signData;
    this.saveSignature(payload);
    this.closePopup();
  }

  public saveSignature(payload) {
    payload.font = this.fontCollectionService.getFont(payload.fontFamily || "");

    this.changeTool.emit(payload);
  }

  public imageOrText(data) {
    return data.startsWith("data:image/") ? "image" : "text";
  }
}
