import { isEmpty } from "lodash";
import { IActionType } from "src/types/event";
import { Action } from "../action";
import { Page } from "../page";
import { AbstractPageItem } from "../abstract-page-item";

export class ActionService {
  public actions: Action[] = [];
  public actionIndex = 0;

  private pageServices: Page[] = [];

  constructor(pageServices: Page[]) {
    this.pageServices = pageServices;
  }

  public initialize(data: any) {
    return this;
  }

  public addAction(action: Action) {
    const actions = this.getActions();
    actions.push(action);
    this.actionIndex = actions.length - 1;
    this.actions = actions;
  }

  public lastAction() {
    return this.actions[this.actionIndex];
  }
  public cloneAction(id) {
    return this.actions.filter(
      (item) =>
        item.item.clone === true &&
        item.type === "ADD" &&
        item.item.cloneID === id
    );
  }

  public undoClone(action: Action[]) {
    action.forEach((item: Action) => {
      item.item.element().remove();
    });

    this.actionIndex = this.actionIndex - action.length;
  }

  public undo(action: Action) {
    if (!action) return;
    const pageService = this.pageServices.find(
      (pageService) => pageService.page.id === action.pageId
    );
    if (!pageService) return;

    switch (action.type) {
      case IActionType.ADD:
        pageService.removeItem(action.item);
        break;
      case IActionType.EDIT:
        const { change } = action;
        if (!isEmpty(change)) {
          const item = action.item.initialize(change.oldStates);
          pageService.createItem(item);
        }
        break;
      case IActionType.DELETE:
        pageService.createItem(action.item);
        break;
    }

    this.actionIndex--;
  }

  public getActions() {
    return this.actions.slice(0, this.actionIndex + 1);
  }
}
