interface IChange {
  oldWidth: number;
  oldHeight: number;
  newHeight: number;
  newWidth: number;
  percentWidth: number;
  percentHeight: number;
}

export abstract class AbstractPageItem {
  id: string;
  className: string;
  itemType: string;
  revision: number;
  isMovable: boolean;
  isRemovable: boolean;
  isCopy: boolean;
  clone: boolean;
  cloneID?: String;
  checkEditable: boolean;
  metaStyles: any;
  signed: any;
  isResizable: boolean;
  type: any;

  static identifier: string;

  public abstract initialize(data: any);
  public abstract template();
  public abstract styles(extraStyles);
  public abstract styleIds();
  public abstract element(container?: Element);
  public abstract jsonData();

  public hookAfter() {}

  public handleResize(event, change: IChange) {}

  public isEditable(currentRevision: number) {
    return this.revision === currentRevision;
  }
}
