import { uniqueId } from "src/app/utils";
import { HIGHLIGHT } from "src/constants";
import { IHightlightPosition } from "src/types/event";
import { appendElement, createTemplate, styleElements } from "src/utils/dom";
import { jsonData } from "src/utils/general";
import { AbstractPageItem } from "./abstract-page-item";

export class Highlight extends AbstractPageItem {
  public id;
  public className = `editor-page-highlight-item`;

  public itemType = HIGHLIGHT;

  private range: IHightlightPosition[] = [];

  public isMovable = false;
  public isRemovable = false;
  public isResizable = false;
  public isCopy = false;

  public checkEditable = true;

  public currentElement = null;

  constructor() {
    super();
  }

  initialize(data: any = {}) {
    const { range, id, revision } = data;
    this.range = range || [];
    this.revision = revision;

    this.id = id || uniqueId(this.className);

    return this;
  }

  public template() {
    const divTemplate = `<div class="${this.className}" id="${this.id}"></div>`;
    return createTemplate(divTemplate);
  }

  public highlight(position: IHightlightPosition, page: Element = null) {
    const style = `left:${position.x}%;top:${position.y}%;`;
    const spanTemplate = `<span class="${this.id}-highlight" style="${style}">&nbsp;</span>`;
    const template = createTemplate(spanTemplate);

    let element = this.currentElement;
    if (!element) {
      element = this.currentElement = this.element(page);
    }

    this.range.push(position);

    appendElement(element, template.content);
  }

  public element(container: Element = null) {
    return (container || document).querySelector(`#${this.id}`);
  }

  public styles() {
    const style = `
            .${this.id}-highlight {
                position: absolute;
                background-color: yellow;
                opacity: 0.1;
                // width: 1%;
            }
        `;

    return styleElements(`${this.id}-highlight`, style);
  }

  public styleIds() {
    return [`#${this.id}`, `#${this.id}-highlight`];
  }

  public jsonData() {
    return jsonData(this, ["fontCollectionService", "identifier"]);
  }
}
