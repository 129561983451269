import { AbstractPageItem } from "src/lib/abstract-page-item";

export interface IEvent {
  type: string;
  data?: any;
}

export interface IToolChange extends IEvent {
  data: {
    tool: string;
    payload?: any;
  };
}

export interface ISignatureTool extends IToolChange {
  data: {
    tool: string;
    payload: ISignaturePayload;
  };
}

export interface ISignaturePayload {
  type: string;
  data: string;
  dataType: string;
  font?: IFont;
  timestamp: string;
  clone: false;
  cloneID?: String;
  position: Object;
  currentPage: string;
}

export interface IFont {
  id: string;
  url: string;
  name: string;
  withFontFace: boolean;
  styleName: string;
  selectable: boolean;
}

export interface IAnnotation {
  id: string;
  text: string;
  font?: IFont;
  color?: string;
}

export interface IHightlightPosition {
  x: number;
  y: number;
}

export enum IActionType {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export interface IPageItems {
  [key: string]: AbstractPageItem[];
}
