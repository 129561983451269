import { isEmpty } from "lodash";
import {
  addStyles,
  appendElement,
  createStyleElement,
  createTemplate,
  removeElements,
} from "src/utils/dom";
import { FontCollection } from "./services/font-collection";
import { Page } from "./page";
import { ActionService } from "./services/action-service";
import { IEvent } from "src/types/event";
import { SIGNATURE, TOOL_CHANGE, UNDO } from "src/constants";
import { formatFromDimensions, getAllPages } from "src/utils/general";

export class Editor {
  private pages;
  private pageServices: Page[] = [];
  public root;
  public container;

  public revision: number;

  public fontCollectionService: FontCollection;
  public actionService: ActionService;

  private pageWidth: number;
  private pageHeight: number;

  private signaturePayload: any = null;
  private updatePayload: any = {};
  private test;
  constructor(id, initialStates: any) {
    // TODO: Inject via dependency injection
    this.fontCollectionService = new FontCollection();

    const container: HTMLElement = document.getElementById(id);
    if (!container) return;

    this.revision =
      initialStates.revision && !isNaN(initialStates.revision)
        ? initialStates.revision * 1 + 1
        : 1;

    this.container = container;
    this.root = container.parentNode;
    this.pages = getAllPages(container);
    this.test = (value: IEvent) => {
      const event = { tool: "signature", payload: value };
      const event2: IEvent = { type: "TOOL_CHANGE", data: event };
      this.broadcast(event2);
      return "Original test function";
    };

    for (const page of this.pages) {
      const items =
        !isEmpty(initialStates) && initialStates.pageItems
          ? initialStates.pageItems[page.id]
          : [];

      const pageService = new Page(page, container, this, items, this.test);
      this.broadcast({ type: "signature", data: this.test });
      this.pageServices.push(pageService);
    }

    this.actionService = new ActionService(this.pageServices);
    this.actionService.initialize(initialStates.actions);

    this.initialize();
  }

  initialize() {
    const { pageWidth, pageHeight } = this.getPageDimensions();

    this.pageWidth = pageWidth;
    this.pageHeight = pageHeight;

    this.addFonts();
    this.addDimensions(this.pageWidth, this.pageHeight);

    addStyles(`page`, this.styles(), this.root);
  }

  addFonts() {
    const fonts = this.fontCollectionService.getSelectableFonts();
    for (const font of fonts) {
      const css = `@import url(${font.url});`;
      const element = createStyleElement(`font-${font.id}`, css);
      appendElement(this.root, element, true);
    }
  }

  addDimensions(pageWidth, pageHeight) {
    const template = createTemplate(`
            <meta id="page-container-width" name="page-container-width" content=${pageWidth} />
            <meta id="page-container-height" name="page-container-height" content=${pageHeight} />`);
    appendElement(this.root, template.content);
  }

  // handleUndo(event: IEvent) {
  //   const action = this.actionService.lastAction();

  //   this.actionService.undo(action);
  // }

  handleUndo(event: IEvent) {
    const action = this.actionService.lastAction();
    const cloneActions = this.actionService.cloneAction(action.item.cloneID);

    if (action.item.clone && action.type === "ADD") {
      this.actionService.undoClone(cloneActions);
    } else if (Object.keys(action).length > 0) {
      this.actionService.undo(action);
    }
  }
  handleSignatureSave(event: IEvent) {
    const {
      data: { payload },
    } = event;

    if (isEmpty(payload)) return;
    this.signaturePayload = payload.signData || {};
  }

  updateSignatureSave(item) {
    const sigHeight = localStorage.getItem("signature-height");
    const sigWidth = localStorage.getItem("signature-width");

    const IniHeight = localStorage.getItem("initial-height");
    const IniWidth = localStorage.getItem("initial-width");

    this.updatePayload.signed = item.signed;
    if (item.dataType === "image") {
      if (item.type === "initial") {
        this.updatePayload.initial = {
          value: item.data,
          width: IniWidth,
          height: IniHeight,
        };
      }
      if (item.type === "signature") {
        this.updatePayload.signature = {
          value: item.data,
          width: sigWidth,
          height: sigHeight,
        };
      }
    } else if (item.dataType === "text") {
      if (item.type === "initial") {
        this.updatePayload.initial = {
          value: item.data,
          fontFamily: item.font.name,
          width: IniWidth,
          height: IniHeight,
        };
      }
      if (item.type === "signature") {
        this.updatePayload.signature = {
          value: item.data,
          fontFamily: item.font.name,
          width: sigWidth,
          height: sigHeight,
        };
      }
    }

    if (isEmpty(this.updatePayload)) return;

    this.signaturePayload = this.updatePayload;
  }

  defaultFont() {
    return this.fontCollectionService.defaultFont();
  }

  defaultFontColor() {
    return "#000000";
  }

  defaultFontSize() {
    return "20";
  }

  getFont(id) {
    return this.fontCollectionService.getFont(id);
  }

  getSignaturePayload() {
    return this.signaturePayload;
  }

  public getPageItems() {
    const items = {};
    for (const pageService of this.pageServices) {
      const id = pageService.getId();
      items[id] = pageService.getItems().map((item) => item.jsonData());
    }
    return items;
  }

  public getRevision() {
    return this.revision;
  }

  public getVersion() {
    return "2";
  }

  dom() {
    const parser = new DOMParser();
    return parser.parseFromString(this.root.innerHTML, "text/html");
  }

  html(dom) {
    return dom.querySelector("html");
  }

  styles() {
    let adjustPageHeightCSS = "";

    const { format, landscape } = this.fileFormat();

    if (["A4", "A5"].includes(format) && landscape) {
      adjustPageHeightCSS = `
                .pf:last-child {
                    border: 1px solid gray;
                }`;
    }

    return `
            @media screen {
                .pf {
                    overflow: visible;
                }
            }

            @media print {
                .pf {
                    overflow: hidden;
                    margin: 0;
                    box-shadow: none;
                    page-break-after: avoid !important;
                    page-break-inside: auto !important;
                    // border: 1px solid red;
                }

                ${adjustPageHeightCSS}
            }
        `;
  }

  fileFormat() {
    return formatFromDimensions(this.pageWidth, this.pageHeight);
  }

  getPageDimensions() {
    const page = this.root.querySelector("[data-page-no]");
    if (!page) return;
    const pageWidth = page.clientWidth;
    const pageHeight = page.clientHeight;
    return { pageWidth, pageHeight };
  }

  cleanup() {
    this.pages.forEach((page) => {
      const id = page.id;
      removeElements(`#page-${id}`, this.root);
    });
  }

  broadcast(event: IEvent) {
    const { type, data } = event;
    // Handle events
    if (type === UNDO) {
      this.handleUndo(event);
    }

    if (
      type === TOOL_CHANGE &&
      data.tool === SIGNATURE &&
      !isEmpty(data.payload) &&
      data.payload.save &&
      data.payload.signData
    ) {
      this.handleSignatureSave(event);
    }
    this.pageServices.forEach((service) =>
      service.broadcast(event, this.pageServices)
    );
  }
}
