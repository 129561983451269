import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { ApiService } from "./api.service";
import { HttpClientModule } from "@angular/common/http";
import { SafePipe } from "./safe.pipe";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { DynamicContentDirective } from "./dynamic-content.directive";
import { AngularDropdownModule } from "angular-dropdown";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TooltipModule } from "ng2-tooltip-directive";
import { PDFComponent } from "./pdf.component";
import { FileService } from "src/services/file.service";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { LoaderComponent } from "./loader/loader.component";
import { SignatureComponent } from "./signature/signature.component";
import { FontCollection } from "src/lib/services/font-collection";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    PDFComponent,
    ToolbarComponent,
    SignatureComponent,

    LoaderComponent,
    SafePipe,
    DynamicContentDirective,
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularDropdownModule,
    NgbModule,
    ToastrModule.forRoot(),
    TooltipModule,
    MatIconModule,
  ],
  providers: [ApiService, FileService, FontCollection],
  bootstrap: [
    // AppComponent
    PDFComponent,
  ],
})
export class AppModule {}
